const React = require('react');
const {displayMessage} = require('./notification.jsx');
const {Feat,EditFeat,NewFeat,printFeat} = require('./renderfeats.jsx');
const {Extension,printExtension,ExtensionDialog,NewExtension} = require('./renderextensions.jsx');
const {RandomTable,printRandomTable,EditRandomTable,NewRandomTable} = require('./renderrandomtables.jsx');
const {Item,ItemDialog, blankItem,getBasicItemType,ItemBookList,itemListFilters,NewItem,printItem,printItemList} = require('./items.jsx');
const {Race,printRace,EditRace,NewRace} = require('./renderraces.jsx');
const {InlineMap} = require('./map.jsx');
const {MapView} = require('./map2.jsx');
const {AudioDialog,printAudio,deleteAudio,RenderAudio,audioListFilters} = require('./renderaudio.jsx');
const {ArtDialog,printArt,deleteArtwork,RenderArt,artListFilters} = require('./renderart.jsx');
const {Renderclass, RenderSubclass, EditClass, NewClass,printClass} = require('./renderclass.jsx');
const {SpellBlock,printSpell,printSpellList,EditSpell,NewSpell,RenderBookSpellList,spellListFilters} = require('./renderspell.jsx');
const {MonsterBlock,printMonster,printNPC, MonsterDetails, NewMonster,monsterListFilters} = require('./rendermonster.jsx');
const {Background, EditBackground,NewBackground,printBackground} = require('./renderbackgrounds.jsx');
const {EncounterDialog,getSuggestedPlayerInfo,deletePlannedEncounter} = require('./encounterview.jsx');
const {CustomItem,CustomDialog,NewCustom,customMapListFilter,printCustom} = require('./customtable.jsx');
const {campaign,areSameDeep} = require('../lib/campaign.js');
const {MapDialog} = require('./rendermaps.jsx');
const {TextBasicEdit,defaultSourceFilter,defaultBookFilter} = require('./stdedit.jsx');
const {LinkHref} = require('./renderhref.jsx');
const {characterListFilters,characterRender,NewCharacter,DialogPlayer} = require('./renderplayers.jsx');
const {PinDialog,deletePin} = require('./pins.jsx');
let BookDialog;


const contentTypeMap = {
    Backgrounds:{
        loader:function() {
            return campaign.getSortedBackgroundsList();
        },
        show:function(name, pageSync, noTitle,hideDescription) {
            return <Background background={name} noTitle={noTitle} noDescription={hideDescription} noSource/>
        },
        print:printBackground,
        edit:function(name, onClose) {
            return <EditBackground background={name} open onClose={onClose}/>
        },
        delete:function(name) {
            campaign.deleteCampaignContent("backgrounds", name);
        },
        createNew:function(onClose) {
            return <NewBackground open onClose={onCloseFn}/>

            function onCloseFn(name) {
                const b = campaign.getBackgroundInfo(name);
                if (name) {
                    onClose({name:name, displayName:b.displayName});
                } else {
                    onClose();
                }
            }
        },
        get:function(name) {
            return {type:"backgrounds", value:campaign.getBackgroundInfo(name)};
        },
        list:function(list) {
            const res = [];
            for (let i in list) {
                const l = list[i];
                const it = campaign.getBackgroundInfo(l.contentId);
                res.push(<tr key={i}><td><LinkHref href={"#backgrounds?id="+encodeURIComponent(l.contentId)}>{it&&it.displayName}</LinkHref></td></tr>);
            }
            return <table className="w-100"><tbody>{res}</tbody></table>;
        },
        listPrint:function(list) {
            const res = [];
            for (let i in list) {
                const l = list[i];
                const it = campaign.getBackgroundInfo(l.contentId);
                res.push(`<tr><td>${it&&it.displayName||""}</td></tr>`);
            }
            return `<table>${res.join("\n")}</table>`;
        },
        dupCheck:function(it) {
            const dup=dupCheckList(campaign.getSortedBackgroundsList(), it);
            if (dup) {
                return ({dup, id:dup.name});
            }
            return {};
        }
    },
    Classes:{
        loader:function () {
            const classes =  campaign.getClassesListByName();
            const subclassTree = campaign.getSubclassTree();
            const list = [];
            for (let i in classes) {
                const c=classes[i];
                list.push({name:c.name, id:{className:c.className}, source:c.source, displayName:c.displayName, edited:c.edited});

                const subclasses = campaign.getSubclasses(c.className);
                for (let x in subclasses) {
                    const sc = subclasses[x];
                    list.push({name:sc.name, id:{className:sc.className, subclassName:sc.subclassName}, source:sc.source, extraPrefix:c.displayName+": ", displayName:sc.displayName, edited:sc.edited});
                }
            }
            for (let i in subclassTree) {
                const clsInfo = campaign.getClassInfo(i);
                if (!clsInfo) {
                    for (let x in subclassTree[i]) {
                        const sc = subclassTree[i][x];
                        list.push({name:sc.name, id:{className:sc.className, subclassName:sc.subclassName}, source:sc.source, extraPrefix:"Unknown Class: ", displayName:sc.displayName, edited:sc.edited});
                    }
                }
            }
            return list;
        },
        show:function(id, pageSync, noTitle,hideDescription) {
            if (!id.subclassName) {
                return <Renderclass cclass={id.className} noTitle={noTitle} noDescription={hideDescription} noSource noSubclasses/>
            } else {
                const subclass = campaign.getSubclassInfo(id.subclassName)
                return <RenderSubclass subclass={subclass} noTitle={noTitle} noDescription={hideDescription} noExpand noSource/>
            }
        },
        print:function(id, hideDescription, header) {
            return printClass(id.className, id.subclassName, hideDescription,header);
        },
        edit:function(id, onClose) {
            return <EditClass cclass={id.className} subclass={id.subclassName} open onClose={onClose}/>
        },
        delete:function(name) {
            campaign.deleteCampaignContent("classes", name);
        },
        createNew:function(onClose) {
            return <NewClass show onClose={onCloseFn}/>

            function onCloseFn(className, subclassName) {
                if (className) {
                    if (subclassName) {
                        const cls = campaign.getSubclassInfo(subclassName);
                        onClose({name:cls.name, id:{className, subclassName}, extraPrefix:className+" - ", displayName:cls.displayName});
                    } else {
                        const cls =campaign.getClassInfo(className);
                        onClose({name:cls.name, id:{className}, displayName:cls.displayName});
                    }
                } else {
                    onClose();
                }
            }
        },
        get:function(id) {
            if (id.subclassName) {
                return {type:"classes", value:campaign.getSubclassInfo(id.subclassName)};
            } else {
                return {type:"classes", value:campaign.getClassInfo(id.className)};
            }
        },
        list:function(list) {
            const res = [];
            for (let i in list) {
                const l = list[i];
                const cls = campaign.getClassInfo(l.contentId.className);
                const subCls = campaign.getSubclassInfo(l.contentId.subclassName);
                res.push(<tr key={i}>
                    <td>
                        <LinkHref href={"#class?class="+encodeURIComponent(l.contentId.className)+(l.contentId.subclassName?("&subclass="+encodeURIComponent(l.contentId.subclassName)):"")}>
                            {cls && cls.displayName}{l.contentId.subclassName?(" - "+((subCls&&subCls.displayName)||"")):null}
                        </LinkHref>
                    </td>
                </tr>);
            }
            return <table className="w-100"><tbody>{res}</tbody></table>;
        },
        listPrint:function(list) {
            const res = [];
            for (let i in list) {
                const l = list[i];
                const cls = campaign.getClassInfo(l.contentId.className);
                const subCls = campaign.getSubclassInfo(l.contentId.subclassName);
                res.push(`<tr><td>${cls?.displayName||""}${l.contentId.subclassName?(" - "+((subCls&&subCls.displayName)||"")):""}</td></tr>`);
            }
            return `<table>${res.join("\n")}</table>`;
        },
        dupCheck:function(it) {
            const dup=dupCheckList(it.subclassName?campaign.getSubclasses(it.className):campaign.getClassesListByName(), it);
            if (dup) {
                if (dup.subclassName) {
                    return ({dup, id:{className:dup.className,subclassName:dup.subclassName}});
                }
                return ({dup, id:{className:dup.className}});
            }
            return {};
        }
    },
    Feats:{
        loader:function () {
            return campaign.getSortedFeatsList();
        },
        show:function(name, pageSync, noTitle,hideDescription) {
            return <Feat feat={name} noTitle={noTitle} noSource/>
        },
        print:printFeat,
        edit:function(name, onClose) {
            return <EditFeat feat={name} open onClose={onClose}/>
        },
        delete:function(name) {
            campaign.deleteCampaignContent("feats", name);
        },
        createNew:function(onClose) {
            return <NewFeat open onClose={onCloseFn}/>

            function onCloseFn(name) {
                if (name) {
                    const feat=campaign.getFeatInfo(name);
                    onClose({name:name, displayName:feat.displayName});
                } else {
                    onClose();
                }
            }
        },
        get:function(name) {
            return {type:"feats", value:campaign.getFeatInfo(name)};
        },
        list:function(list) {
            const res = [];
            for (let i in list) {
                const l = list[i];
                const feat = campaign.getFeatInfo(l.contentId);
                res.push(<tr key={i}><td><LinkHref href={"#feats?id="+encodeURIComponent(l.contentId)}>{feat&&feat.displayName}</LinkHref></td></tr>);
            }
            return <table className="w-100"><tbody>{res}</tbody></table>;
        },
        listPrint:function(list) {
            const res = [];
            for (let i in list) {
                const l = list[i];
                const feat = campaign.getFeatInfo(l.contentId);
                res.push(`<tr><td>${feat?.displayName||""}</td></tr>`);
            }
            return `<table>${res.join("\n")}</table>`;
        },
        dupCheck:function(it) {
            const dup=dupCheckList(campaign.getSortedFeatsList(), it);
            if (dup) {
                return ({dup, id:dup.name});
            }
            return {};
        }
    },
    Extensions:{
        loader:function () {
            return campaign.getSortedExtensionsList();
        },
        show:function(name, pageSync, noTitle,hideDescription) {
            return <Extension extension={name} noTitle={noTitle} noSource/>
        },
        print:printExtension,
        edit:function(name, onClose) {
            return <ExtensionDialog extension={name} open onClose={onClose}/>
        },
        delete:function(name) {
            campaign.deleteCampaignContent("extensions", name);
        },
        createNew:function(onClose) {
            return <NewExtension open onClose={onCloseFn}/>

            function onCloseFn(name) {
                if (name) {
                    const extension=campaign.getExtensionInfo(name);
                    onClose({name:name, displayName:extension.displayName});
                } else {
                    onClose();
                }
            }
        },
        get:function(name) {
            return {type:"extensions", value:campaign.getExtensionInfo(name)};
        }
    },
    "Random Encounters":{
        loader:function () {
            return campaign.getRandomTables();
        },
        show:function(name, pageSync, noTitle,hideDescription, size, addToEncounter) {
            return <RandomTable randomtable={name} noTitle={noTitle} noSource addToEncounter={addToEncounter} pageSync={pageSync}/>
        },
        print:printRandomTable,
        edit:function(name, onClose) {
            return <EditRandomTable randomtable={name} open onClose={onClose}/>
        },
        delete:function(name) {
            campaign.deleteCampaignContent("randomtables", name);
        },
        createNew:function(onClose) {
            return <EditRandomTable open onClose={onCloseFn}/>

            function onCloseFn(name) {
                if (name) {
                    const rt = campaign.getRandomTableInfo(name);
                    onClose({name:name, displayName:rt.displayName},true);
                } else {
                    onClose();
                }
            }
        },
        get:function(name) {
            return {type:"randomtables", value:campaign.getRandomTableInfo(name)};
        }
    },
    Items:{
        loader:function () {
            return campaign.getSortedItemsList();
        },
        show:function(name, pageSync, noTitle,hideDescription) {
            return <Item item={campaign.getItem(name)} noTitle={noTitle} noSource/>
        },
        print:printItem,
        edit:function(name, onClose) {
            const item = campaign.getItem(name);
            return <ItemDialog openEditable editable open item={item} showGamesystem noEditTitle onClose={doOnClose}/>

            function doOnClose(item) {
                if (item) {
                    campaign.updateCampaignContent("items", item);
                }
                onClose();
            }
        },
        delete:function(name) {
            campaign.deleteCampaignContent("items", name);
        },
        createNew:function(onClose) {
            return <NewItem open onClose={doOnClose}/>

            function doOnClose(item) {
                if (item && item.displayName && item.displayName!="") {
                    campaign.updateCampaignContent("items", item);
                    onClose({name:item.name, displayName:item.displayName},true);
                } else {
                    onClose();
                }
            }
        },
        get:function(name) {
            return {type:"items", value:campaign.getItem(name)};
        },
        list:function(list) {
            return <ItemBookList list={list}/>
        },
        listPrint:printItemList,
        listFilters:itemListFilters
    },
    Monsters:{
        loader:function () {
            const list = campaign.getMonsterListByName().concat([]);
            list.sort(function(a,b){
                return a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase());
            });
            return list;
        },
        show:function(name, pageSync, noTitle,hideDescription) {
            const mon = campaign.getMonster(name);
            if (!mon) {
                return <div>Monster "{name}" not found.</div>
            }
            return <div>
                <div className="mh--3 bw1 ba b--black monsterblockborder"/>
                <div className="mh1 shadow-3 pa1 monsterblockbackground">
                    <MonsterBlock monster={mon} nomenu noSource noClick/>
                </div>
                <div className="mh--3 bw1 ba b--black monsterblockborder"/>
            </div>;
        },
        print:printMonster,
        edit:function(name, onClose) {
            return <MonsterDetails monster={name} open editable onClose={onClose}/>
        },
        delete:function(name) {
            campaign.deleteCampaignContent("monsters", name);
        },
        createNew:function(onClose) {
            return <NewMonster open onClose={onCloseFn}/>

            function onCloseFn(name) {
                if (name) {
                    const mon = campaign.getMonster(name);
                    onClose({name:name, displayName:mon.displayName});
                } else {
                    onClose();
                }
            }
        },
        get:function(name) {
            return {type:"monsters", value:campaign.getMonster(name)};
        },
        list:function(list) {
            const res = [];
            for (let i in list) {
                const l = list[i];
                const mon=campaign.getMonsterInfo(l.contentId);
                res.push(<tr key={i}><td><LinkHref href={"#monster?id="+encodeURIComponent(l.contentId)}>{mon&&mon.displayName}</LinkHref></td></tr>);
            }
            return <table className="w-100"><tbody>{res}</tbody></table>;
        },
        listPrint:function(list) {
            const res = [];
            for (let i in list) {
                const l = list[i];
                const mon=campaign.getMonsterInfo(l.contentId);
                res.push(`<tr><td>${mon&&mon.displayName||""}</td></tr>`);
            }
            return `<table>${res.join("\n")}</table>`;
        },
        dupCheck:function(it) {
            const dup=dupCheckList(campaign.getMonsterListByName(), it);
            if (dup) {
                return ({dup, id:dup.name});
            }
            return {};
        },
        listFilters:monsterListFilters
    },
    Spells:{
        loader:function () {
            const spells = campaign.getSpellListByName().concat([]);
            spells.sort(function(a,b){
                return (a.displayName||"").toLowerCase().localeCompare((b.displayName||"").toLowerCase());
            });
            return spells;
        },
        show:function(name, pageSync, noTitle,hideDescription) {
            return <SpellBlock spell={name} noTitle={noTitle} noSource/>
        },
        print:printSpell,
        edit:function(name, onClose) {
            return <EditSpell spell={name} open onClose={onClose}/>
        },
        delete:function(name) {
            campaign.deleteCampaignContent("spells", name);
        },
        createNew:function(onClose) {
            return <NewSpell open onClose={onCloseFn}/>

            function onCloseFn(name) {
                if (name) {
                    const spell = campaign.getSpell(name);
                    onClose({name:name, displayName:spell.displayName});
                } else {
                    onClose();
                }
            }
        },
        get:function(name) {
            return {type:"spells", value:campaign.getSpell(name)};
        },
        list:function(list) {
            return <RenderBookSpellList list={list}/>;
        },
        listPrint:printSpellList,
        listFilters:spellListFilters,
        dupCheck:function(it) {
            const dup=dupCheckList(campaign.getSpellListByName(), it);
            if (dup) {
                return ({dup, id:dup.name});
            }
            return {};
        }
    },
    Races:{
        loader:function () {
            return campaign.getRaces();
        },
        show:function(name, pageSync, noTitle,hideDescription) {
            return <Race race={name} noTitle={noTitle} noDescription={hideDescription} noSub noSource/>
        },
        print:printRace,
        edit:function(name,onClose) {
            return <EditRace race={name} open onClose={onClose}/>
        },
        delete:function(name) {
            campaign.deleteCampaignContent("races", name);
        },
        createNew:function(onClose) {
            return <NewRace open onClose={onCloseFn}/>

            function onCloseFn(name) {
                if (name) {
                    const race=campaign.getRaceInfo(name);
                    onClose({name:name, displayName:race.displayName});
                } else {
                    onClose();
                }
            }
        },
        get:function(name) {
            return {type:"races", value:campaign.getRaceInfo(name)};
        },
        list:function(list) {
            const res = [];
            for (let i in list) {
                const l = list[i];
                const race = campaign.getRaceInfo(l.contentId);
                res.push(<tr key={i}><td><LinkHref href={"#race?id="+encodeURIComponent(l.contentId)}>{race&&race.displayName}</LinkHref></td></tr>);
            }
            return <table className="w-100"><tbody>{res}</tbody></table>;
        },
        listPrint:function(list) {
            const res = [];
            for (let i in list) {
                const l = list[i];
                const race = campaign.getRaceInfo(l.contentId);
                res.push(`<tr><td>${race?.displayName||""}</td></tr>`);
            }
            return `<table>${res.join("\n")}</table>`;
        },
        dupCheck:function(it) {
            const dup=dupCheckList(campaign.getRaces(), it);
            if (dup) {
                return ({dup, id:dup.name});
            }
            return {};
        }
    },
    Maps:{
        useImages:true,
        loader:function () {
            return campaign.getMaps();
        },
        edit:function(name,onClose) {
            return <MapDialog name={name} open onClose={onClose}/>
        },
        createNew:function(onClose) {
            return <MapDialog open onClose={onCloseFn}/>

            function onCloseFn(name) {
                if (name) {
                    const map=campaign.getMapInfo(name);
                    onClose({name:name, displayName:map.displayName}, true);
                } else {
                    onClose();
                }
            }
        },
        delete:function(name) {
            campaign.deleteCampaignContent("maps", name);
        },
        show:function(name, pageSync, noTitle,hideDescription) {

            if (campaign.showBetaMap) {
                return <MapView className="w-100" noPlaceHolder variant="inline" mapName={name} onClick={onClick}/>;
            } else {
                return <InlineMap className="w-100" noPlaceHolder mapName={name} onClick={onClick}/>;
            }

            function onClick(event) {
                if (pageSync) {
                    event.preventDefault();
                    event.stopPropagation();
                    pageSync.emit("clickcontent", {contentType:"Map", id:name, event});
                }
            }
        },
        print:function (id,noTitle,header) {
            const list=[];
            const map = campaign.getMapInfo(id);
            if (!map) {
                return;
            }

            return printArt(map.art, noTitle, header);
        },
        get:function(name) {
            return {type:"maps", value:campaign.getMapInfo(name)};
        }
    },
    Pins:{
        loader:function() {
            return campaign.getSortedPinsList();
        },
        edit:function(name, onClose) {
            return <PinDialog id={name} open onClose={onClose}/>
        },
        delete:function(name) {
            deletePin(name);
        }
    },
    Encounters:{
        loader:function() {
            return campaign.getPlannedEncounters();
        },
        edit:function(name, onClose) {
            return <EncounterDialog encounter={name} open onClose={onClose}/>
        },
        createNew:function(onClose) {
            return <TextBasicEdit show label="New Encounter Name" onChange={onNew} />

            function onNew(name) {
                if (!name){
                    onClose();
                    return;
                }
                const newEncounter = Object.assign({name:campaign.newUid(), displayName:name}, getSuggestedPlayerInfo());

                campaign.updateCampaignContent("plannedencounters", newEncounter);
                onClose(newEncounter)
            }
        },
        delete:function(name) {
            deletePlannedEncounter(name);
        }
    },
    Artwork:{
        useImages:true,
        showHeader:true,
        loader:function () {
            return campaign.getArt();
        },
        createNew:function(onClose) {
            return <ArtDialog open onClose={onNew} />

            function onNew(name) {
                if (!name){
                    onClose();
                    return;
                }
                const art = campaign.getArtInfo(name);
                onClose({name, displayName:art.displayName},true)
            }
        },
        show:function(name, pageSync, noTitle,hideDescription,size,addToEncounter, editable) {
            return <RenderArt name={name} frameSize={size} noClick={editable} pageSync={pageSync}/>;
        },
        print:printArt,
        listPrint:function(list) {
            const res = [];
            for (let i in list) {
                const l = list[i];

                const art = campaign.getArtInfo(l.contentId);
                if (art) {
                    let height=art.imgHeight, width=art.imgWidth;

                    if (height > width) {
                        width = width/height*150;
                        height = 150;
                    } else {
                        height = height/width*150;
                        width=150;
                    }
                    res.push(`<div style="display: inline-block; text-align:center; margin:5px"><div style="height:150px; width:150px"><img height="${height}" width="${width}" src="${art.url||""}"/></div><div>${art.displayName||""}</div></div>`);
                }
            }
            return `<div>${res.join("\n")}</div>`;
        },
        edit:function(name,onClose) {
            return <ArtDialog name={name} open onClose={onClose}/>
        },
        delete:function(name) {
            deleteArtwork(name);
        },
        get:function(name) {
            return {type:"art", value:campaign.getArtInfo(name)};
        },
        list:function(list, pageSync) {
            const res = [];
            for (let i in list) {
                const l = list[i];

                const art = campaign.getArtInfo(l.contentId);
                if (art) {
                    res.push(<div key={i} className="dib tc ma1">
                        <div style={{height:"150px", width:"150px"}}>
                            {art.imgHeight>art.imgWidth?<img height="100%" src={art.url}  onClick={clickArt.bind(null, l.contentId)}/>:<img width="100%" src={art.url}  onClick={clickArt.bind(null, l.contentId)}/>}
                        </div>
                        <div>{art.displayName}</div>
                    </div>);
                }
            }
            return <div>{res}</div>;

            function clickArt(id, event) {
                if (pageSync) {
                    pageSync.emit("clickcontent", {contentType:"Art", id, event});
                }
            }
        },
        listFilters:artListFilters
    },
    "Audio Clips":{
        tile:true,
        showHeader:true,
        loader:function () {
            return campaign.getAudio();
        },
        createNew:function(onClose) {
            return <AudioDialog open onClose={onNew} />

            function onNew(name) {
                if (!name){
                    onClose();
                    return;
                }
                const audio = campaign.getAudioInfo(name);
                onClose({name, displayName:audio.displayName},true)
            }
        },
        show:function(name, pageSync, noTitle,hideDescription,size,addToEncounter, editable) {

            return <RenderAudio name={name} pageSync={pageSync} useThumb/>;
        },
        print:printAudio,
        edit:function(name,onClose) {
            return <AudioDialog name={name} open onClose={onClose}/>;
        },
        delete:function(name) {
            deleteAudio(name);
        },
        get:function(name) {
            return {type:"audio", value:campaign.getAudioInfo(name)};
        },
        list:function(list, pageSync) {
            const res = [];
            for (let i in list) {
                const l = list[i];

                const audio = campaign.getAudioInfo(l.contentId);
                if (audio) {
                    res.push(<tr key={i} onClick={clickAudio.bind(null, l.contentId)}>
                        <td><RenderAudio name={audio.name} pageSync={pageSync} className="maintext titlecolor"/></td>
                    </tr>);
                }
            }
            return <table><tbody>{res}</tbody></table>;

            function clickAudio(id, event) {
                if (pageSync) {
                    pageSync.emit("clickcontent", {contentType:"Audio Clips", id, event});
                }
            }
        },
        listFilters:audioListFilters
    },
    Books:{
        loader:function () {
            return campaign.getBookList();
        },
        edit:function(name,onClose) {
            if (!BookDialog) {
                // have to load here to prevent a require loop
                BookDialog = require('./book.jsx').BookDialog;
            }
            return <BookDialog editable bookname={name} open onClose={onClose}/>;
        },
        delete:function(name) {
            // have to load here to prevent a require loop
            return(require('./book.jsx').deleteBook(name,true));
        },
        createNew:function(onClose) {
            const {NewBook} = require('./book.jsx');
            return <NewBook open onClose={onCloseFn}/>;
            function onCloseFn(name) {
                const b = campaign.getBookInfo(name);
                if (name && b) {
                    onClose({name:name, displayName:b.displayName});
                } else {
                    onClose();
                }
            }
        }
    },
    "Pregenerated Characters":{
        alwaysEditable:true,
        loader:function() {
            return campaign.getPregens();
        },
        show:function(name) {
            const char = campaign.getMyCharacterInfo(name);
            if (char) {
                return characterRender(char);
            } else {
                return <div>Pregenerated character not found</div>
            }
        },
        print:printNPC,
        edit:function(name, onClose) {
            return <DialogPlayer type="mycharacters" pregen name={name} show hideDice onClose={onClose}/>
        },
        delete:function(name) {
            campaign.deleteCampaignContent("mycharacters", name);
        },
        createNew:function(onClose) {
            return <NewCharacter open pregen type="mycharacters" onClose={onCloseFn}/>

            function onCloseFn(name) {
                const b = campaign.getMyCharacterInfo(name);
                if (name && b) {
                    onClose({name:name, displayName:b.displayName});
                } else {
                    onClose();
                }
            }
        },
        get:function(name) {
            return {type:"mycharacters", value:campaign.getMyCharacterInfo(name)};
        },
        listFilters:characterListFilters
    },
}


const bookContentTypeList = ["Artwork","Audio Clips","Backgrounds", "Classes","Extensions", "Feats", "Items", "Maps", "Monsters",  "Pregenerated Characters", "Races", "Random Encounters", "Spells"];
const myCreationsTypeList = ["Books","Artwork","Audio Clips","Backgrounds", "Classes", "Encounters","Extensions", "Feats", "Items", "Maps", "Monsters",  "Pins", "Pregenerated Characters", "Races", "Random Encounters", "Spells"];
const myCreationsNewTypeList = ["Artwork","Audio Clips","Backgrounds", "Classes", "Encounters","Extensions", "Feats", "Items", "Maps", "Monsters",  "Pregenerated Characters", "Races", "Random Encounters", "Spells"];

function dupCheckList(list, it) {
    const gs = campaign.defaultGamesystem;
    let maybe = null;

    for (let i in list) {
        const ic = list[i];
        if ((ic.displayName||"").trim().toLowerCase() == (it.displayName||"").trim().toLowerCase()) {
            if (ic.gamesystem == gs) {
                return ic;
            }
            maybe = ic;
        }
    }
    return maybe;
}

function getBookContentTypes() {
    return bookContentTypeList.concat(addCustomTables());
}

function getMyCreationsContentTypes() {
    return myCreationsTypeList.concat(addCustomTables());
}

function getMyCreationsNewContentTypes() {
    return myCreationsNewTypeList.concat(addCustomTables());
}

function addCustomTables(){
    const list = campaign.getCustomTablesList();

    for (let i in list) {
        const li=list[i];
        if (!contentTypeMap[li]) {
            contentTypeMap[li] = getCustomTableContentType(li);
        }
    }
    return list;
}

function getCustomTableContentType(contentType) {
    return {
        loader:function () {
            const list= campaign.getSortedCustomList(contentType);
            const ret = [];
            for (let i in list) {
                const li=list[i];
                ret.push({name:li.name, displayName:li.displayName, id:{name:li.name, id:li.id}, source:li.source, keywords:li.keywords, edited:li.edited});
            }
            return ret;
        },
        show:function(id, pageSync, noTitle) {
            return <CustomItem type={contentType} id={id.id} noTitle={noTitle} noSource pageSync={pageSync}/>
        },
        edit:function(id,onClose) {
            return <CustomDialog type={contentType} id={id.id} open openEdit onClose={onClose}/>
        },
        delete:function(name) {
            campaign.deleteCampaignContent("customTypes", name);
        },
        createNew:function(onClose) {
            return <NewCustom open onClose={onNew} type={contentType}/>

            function onNew(item) {
                if (!item){
                    onClose();
                    return;
                }
                onClose({name:item.name, displayName:item.displayName, id:{name:item.name, id:item.id}},true);
            }
        },
        get:function(id) {
            return {type:"customTypes", value:campaign.getCustom(contentType, id.id||id.displayName)};
        },
        list:function(list) {
            const res = [];
            for (let i in list) {
                const l = list[i];
                const ct = campaign.getCustom(contentType, l.contentId.displayName||l.contentId.id); //displayName due to migration from names to ids
                res.push(<tr key={i}><td><LinkHref href={"#customlist?id="+encodeURIComponent(l.contentId.displayName||l.contentId.id)+"&type="+encodeURIComponent(contentType)}>{ct&&ct.displayName}</LinkHref></td></tr>);
            }
            return <table className="w-100"><tbody>{res}</tbody></table>;
        },
        listPrint:function(list) {
            const res = [];
            for (let i in list) {
                const l = list[i];
                const ct = campaign.getCustom(contentType, l.contentId.displayName||l.contentId.id); //displayName due to migration from names to ids
                res.push(`<tr><td>${ct?.displayName||""}</td></tr>`);
            }
            return `<table>${res.join("\n")}</table>`;
        },
        print:function(id, hideDescription,header) {return printCustom(contentType, id.displayName||id.id, hideDescription, header);},
        dupCheck:function(it) {
            const dup=dupCheckList(campaign.getSortedCustomList(contentType), it);
            if (dup) {
                return ({dup, id:{name:dup.name, id:dup.id}});
            }
            return {};
        },
        listFilters:customMapListFilter
    }
}

export {
    getBookContentTypes,
    getMyCreationsContentTypes,
    getMyCreationsNewContentTypes,
    contentTypeMap,
}