'use strict';

const stdvalues = require('../lib/stdvalues.js');
const {Character} = require('./character.js');
const {signedNum,damageTypesList,fullSpellInfo,halfSpellInfo,halfPlusSpellInfo,thirdSpellInfo,pactSpellInfo,halfPactSpellInfo,thirdPactSpellInfo}=stdvalues;
const {campaign,upgradeItem, replaceMetawords, areSameDeep,areSameDeepInst,getExtensionEntryCheckFn,extensionsFromSaved} = require('../lib/campaign.js');
const Parser = require("../lib/dutils.js").Parser;
const {doRoll,getStringFromDice,getRollSum,getDiceFromString,damagesFromExtraDamage} = require('../src/diceroller.jsx');
const {snackMessage,displayMessage} = require('../src/notification.jsx');
const {Chat} = require('../lib/chat.js');
const {shouldDurationExpire,nameFromFeatureParams,addExtraDice,proficiencyBonus,mergeAbilityBonus,proficiencyMerge} = require('./character.js');

const fTemplateName = "Custom Stat Blocks";

class MonObj extends Character {
    constructor(mon, crow, includeConditions, onChangeMon, onChangeCrow) {
        super();
        this.state = mon;
        this.crow = crow;
        this.includeConditions = includeConditions;
        this.onChangeMon = onChangeMon;
        this.onChangeCrow = onChangeCrow;
        this.spellSlots=[];
        this.isMon=true;

        this.computeValues();
    }

    computeValues() {
        const mon=this.state;
        const t=this;
        const abilities = {};
        const skills = {};
        const vulnerable={};
        const resist={};
        const immune={};
        const conditionImmune={};
        const languages={};
        const senses={};
        const unique =mon.unique || mon.npc;
        let speed={};
        let proficiency=0;
        let level = 0;
        let hpMod = 0;
        let maxhp=Number((mon.hp && (mon.hp.maxHP || mon.hp.average)) || 0);
        let acBonus=0;
        let noArmorBonus=0;
        let noArmorShieldBonus=0;
        let lightArmorBonus=0;
        let mediumArmorBonus=0;
        let heavyArmorBonus=0;
        let anyArmorBonus=0;
        let speedBonus=0;
        let damageBonus=0;
        let noArmorSpeedBonus=0;
        let noArmorShieldSpeedBonus=0;
        let lightArmorSpeedBonus=0;
        let mediumArmorSpeedBonus=0;
        let spellAttackBonus=0;
        let spellDCBonus=0;
        let savingThrowBonus=0;
        let savingThrowAbilityBonus={};
        let skillCheckBonus=0;
        let skillCheckAbilityBonus={};
        let perceptionBonus=0;
        let stealthBonus=0;
        let initiativeBonus=0;
        const initiativeAbilityBonus={};
        const namedValues={};
        let unarmedAttacks = [];
        let mediumArmorMax=2;
        let sizeAdjust=0;
        let customMods = {};
        let addSpellSlots = [0,0,0,0,0,0,0,0,0];
        let initiativeDice=[], saveDice=[], skillDice=[], attackDice=[], spellDice=[], abilitiesDice={};
        const modifiers = {};
        const armorInfo = armorInfoFromMonAC(mon.ac);
        if (this.crow && !unique) {
            armorInfo.ac = this.crow.ac || armorInfo.ac;
        }
        if (!unique && this.crow?.maxHP) {
            maxhp = Number(this.crow.maxHP);
        }
        this.armorInfo = armorInfo;
        const fTemplate = campaign.getCustom(fTemplateName,this.state.fTemplate);

        let baseACs = []; 

        stdvalues.abilities.forEach(function(ability){
            const score = Number(mon[ability]||10);
            abilities[ability] = {
                score,
                mods:0,
                max:30,
                modifier:Math.floor(score/2)-5
            };
        });

        campaign.getAllSkillsWithAbilities().forEach(function(skill){
            skills[skill.skill] = {
                skill:skill.skill,
                modifier:0,
                ability:skill.mod
            }
        });

        // setup level for modifiers that depend on level
        level = (this.includeConditions && this.featureOptions.levelOverride) || Math.min(30,Math.trunc(mon.crsort));
        this.level = level;

        proficiency = ((level>1)?(Math.trunc((level-1)/4) +2):2);
        this.proficiency = proficiency;
        this.abilities = abilities;

        this.getAllModifiers(modifiers);

        this.modifiers = modifiers;

        let expertProficient;

        for (let i in modifiers) {
            const mod = modifiers[i];

            if (mod.abilityScores) {
                for (let x in mod.abilityScores) {
                    const am = mod.abilityScores[x];

                    abilities[x].score += (am.modifier||0);
                    abilities[x].mods += (am.modifier||0);
                    abilities[x].minValue = Math.max(abilities[x].minValue||0, am.minValue||0 );
                    abilities[x].max = Math.max(abilities[x].max||0, am.maxValue||0 );
                    abilities[x].proficiency = proficiencyMerge(abilities[x].proficiency, am.proficiency);
                }
            }

            if (mod.skills) {
                for (let x in mod.skills) {
                    const s = mod.skills[x];

                    if (skills[x]) {
                        skills[x].modifier += (s.modifier||0);
                        skills[x].proficiency = proficiencyMerge(skills[x].proficiency, s.proficiency);
                    }
                }
            }

            if (mod.checks) {
                for (let x in mod.checks) {
                    const s = mod.checks[x];

                    abilities[x].checkProficiency = proficiencyMerge(abilities[x].checkProficiency, s.proficiency);
                }
            }


            if (mod.baseACs && mod.baseACs.length) {
                baseACs = baseACs.concat(mod.baseACs);
            }

            if (mod.vulnerable) {
                Object.assign(vulnerable, mod.vulnerable);
            }
            if (mod.resist) {
                Object.assign(resist, mod.resist);
            }
            if (mod.immune) {
                Object.assign(immune, mod.immune);
            }
            if (mod.conditionImmune) {
                Object.assign(conditionImmune, mod.conditionImmune);
            }

            if (mod.languages) {
                Object.assign(languages, mod.languages);
            }

            if (mod.senses) {
                for (let x in mod.senses) {
                    const s = mod.senses[x];
                    const cs = senses[x];

                    if (!cs) {
                        senses[x] = Object.assign({},s);
                    } else {
                        if (s.number) {
                            cs.number = Math.max(cs.number||0, s.number);
                        }
                    }
                }
            }

            if (mod.speed) {
                for (let x in mod.speed) {
                    const s = mod.speed[x];
                    const cs = speed[x];

                    if (!cs) {
                        speed[x] = Object.assign({},s);
                    } else {
                        if (s.number) {
                            cs.number = Math.max(cs.number||0, s.number);
                        }
                        if (s.walking) {
                            cs.walking=true;
                        }
                    }
                }
            }

            if (mod.slots) {
                for (let i=0; i<9; i++) {
                    addSpellSlots[i] += (mod.slots[i]||0)
                }
            }

            spellAttackBonus += mod.spellAttackBonus||0;
            spellDCBonus += mod.spellDCBonus||0;
            savingThrowBonus += mod.savingThrowBonus||0;
            mergeAbilityBonus(savingThrowAbilityBonus, mod.savingThrowBonusAbilities);
            skillCheckBonus += mod.skillCheckBonus||0;
            mergeAbilityBonus(skillCheckAbilityBonus, mod.skillCheckBonusAbilities);
            perceptionBonus += mod.perceptionBonus||0;
            stealthBonus += mod.stealthBonus || 0;
            initiativeBonus += mod.initiativeBonus||0;
            if (mod.initiativeAbilityBonus) {
                Object.assign(initiativeAbilityBonus, mod.initiativeAbilityBonus);
            }
            proficiency += mod.proficiencyBonus||0;

            if (mod.unarmedAttacks) {
                unarmedAttacks = unarmedAttacks.concat(mod.unarmedAttacks);
            }

            damageBonus += mod.damageBonus||0;
    
            hpMod += (mod.hpMod||0);

            if (mod.initiativeDice) {
                initiativeDice = initiativeDice.concat(mod.initiativeDice);
            }
            if (mod.saveDice) {
                saveDice = saveDice.concat(mod.saveDice);
            }
            if (mod.skillDice) {
                skillDice = skillDice.concat(mod.skillDice);
            }
            if (mod.attackDice) {
                attackDice = attackDice.concat(mod.attackDice);
            }
            if (mod.spellDice) {
                spellDice = spellDice.concat(mod.spellDice);
            }
            if (mod.abilitiesDice) {
                for (let abName in mod.abilitiesDice) {
                    abilitiesDice[abName] = (abilitiesDice[abName]||[]).concat(mod.abilitiesDice[abName])
                }
            }
            if (mod.sizeAdjust) {
                sizeAdjust += mod.sizeAdjust;
            }
            if (mod.customMods) {
                for (let c in mod.customMods) {
                    customMods[c] = (customMods[c]||"")+","+mod.customMods[c];
                }
            }
        }

        if (initiativeAbilityBonus.prof2) {
            initiativeBonus+= (2*proficiency);
        } else if (initiativeAbilityBonus.prof) {
            initiativeBonus+= (proficiency);
        }

        stdvalues.abilities.forEach(function(ability){
            const a = abilities[ability];
            const maxVal = a.max||20;
            if (a.score > maxVal) {
                a.score = maxVal;
            }
            if (a.score < a.minValue) {
                a.score = a.minValue;
            }
            a.modifier = Math.floor(a.score/2)-5;
            a.spellSave = a.modifier + proficiencyBonus(proficiency, a.proficiency)+savingThrowBonus;
            if (initiativeAbilityBonus[ability]) {
                if (a.modifier >0) {
                    initiativeBonus+=a.modifier;
                }
            }
        });

        stdvalues.abilities.forEach(function(ability){
            const a = abilities[ability];
            let stbonus = 0;
            for (let x in savingThrowAbilityBonus[ability]) {
                if (x=="all") {
                    stbonus += savingThrowAbilityBonus[ability][x];
                } else if (abilities[x].modifier> 0) {
                    stbonus += (abilities[x].modifier);
                }
            }
            a.spellSave += stbonus;
            a.spellSaveBonus = stbonus;
        });

        for (let i in skills) {
            const s = skills[i];

            let scbonus = skillCheckBonus;
            for (let x in skillCheckAbilityBonus[i]) {
                if (x=="all") {
                    scbonus += skillCheckAbilityBonus[i][x];
                } else if (abilities[x].modifier> 0) {
                    scbonus += (abilities[x].modifier);
                }
            }

            const ability = abilities[s.ability];

            s.proficiency = proficiencyMerge(s.proficiency, ability.checkProficiency);
            s.modifier = s.modifier + ability.modifier + proficiencyBonus(proficiency, s.proficiency) + scbonus;
        }

        const acAbilities={};
        const namedUsageMax = {};
        for (let i in modifiers) {
            const mod = modifiers[i];

            acBonus += (calcAcBonus(mod.acBonus)+ calcAbilityBonus(mod.allArmorAbilityBonus));
            noArmorBonus += (calcAcBonus(mod.noArmorBonus) + calcAbilityBonus(mod.noArmorAbilityBonus));
            noArmorShieldBonus += (calcAcBonus(mod.noArmorShieldBonus) + calcAbilityBonus(mod.noArmorShieldAbilityBonus));
            lightArmorBonus += (mod.lightArmorBonus||0 + calcAbilityBonus(mod.lightArmorAbilityBonus));
            mediumArmorBonus += (mod.mediumArmorBonus||0 + calcAbilityBonus(mod.mediumArmorAbilityBonus));
            heavyArmorBonus += (mod.heavyArmorBonus||0 + calcAbilityBonus(mod.heavyArmorAbilityBonus));
            anyArmorBonus += (mod.anyArmorBonus||0 + calcAbilityBonus(mod.anyArmorAbilityBonus));

            speedBonus +=mod.speedBonus||0;
            noArmorSpeedBonus+=mod.noArmorSpeedBonus||0;
            noArmorShieldSpeedBonus+=mod.noArmorShieldSpeedBonus||0;
            lightArmorSpeedBonus+=mod.lightArmorSpeedBonus||0;
            mediumArmorSpeedBonus+=mod.mediumArmorSpeedBonus||0;
            mediumArmorMax = Math.max(mediumArmorMax || 0, mod.mediumArmorMax ||0);

            function calcAcBonus(bonus) {
                if (typeof(bonus)==="string") {
                    if (!acAbilities[bonus]) {
                        acAbilities[bonus]=true;
                        return abilities[bonus].modifier;
                    }
                    return 0;
                }
                return bonus || 0;
            }

            function calcAbilityBonus(aca) {
                let bonus=0;
                for (let a in aca) {
                    if (a=="proficiency") {
                        bonus+=proficiency;
                    } else {
                        bonus+=abilities[a].modifier;
                    }
                }
                return bonus;
            }

            for (let i in mod.levelValues) {
                namedValues[i] = Math.max(namedValues[i]||0, mod.levelValues[i]||0);
            }
        }
        const walk = speed.walk;
        if (walk && walk.number) {
            const wn = walk.number
            for (let i in speed) {
                const sp = speed[i];
                if (sp.walking) {
                    sp.number = Math.max(sp.number||0, wn);
                }
            }
        }

        for (let i in skills) {
            const s  = skills[i];
            namedValues[i.toLowerCase()] = s.modifier;
            namedValues[i.toLowerCase()+"1"] = Math.max(1, s.modifier);
        }

        for (let i in abilities) {
            const a  = abilities[i];
            namedValues[i.toLowerCase()] = a.modifier;
            namedValues[i.toLowerCase()+"1"] = Math.max(1, a.modifier);
        }
        namedValues.proficiency= proficiency;
        namedValues.pb= proficiency;
        namedValues.level = level;

        if (this.conditions) {
            for (let c in this.conditions) {
                const cond = this.conditions[c];
                namedValues["condition."+c.toLowerCase()]=cond.level||1
            }
        }

        for (let i in modifiers) {
            const mod = modifiers[i];

            for (let uv in mod.usageValues) {
                const uvals = mod.usageValues[uv];
                for (let x in uvals) {
                    const uv = uvals[x];
                    if (uv.usage.usageName) {
                        const name = uv.usage.usageName.toLowerCase();
                        const max = this.getUsageMax(uv.usage, uv.level-1, abilities, false,proficiency, namedValues);
                        namedUsageMax[name] = Math.max(namedUsageMax[name]||0, max||0);
                    }
                }
            }
        }

        for (let i in modifiers) {
            const mod = modifiers[i];

            for (let un in mod.usageBonuses) {
                const uval = mod.usageBonuses[un];
                const name = un.toLowerCase();
                namedUsageMax[name] = (namedUsageMax[name]||0)+uval;
                if (namedValues[name]!=null) {
                    namedValues[name] += uval;
                }
            }
        }

        for (let i in modifiers) {
            const mod = modifiers[i];
            if (mod.maxHP) {
                const dice = getDiceFromString(mod.maxHP,0,true,namedValues);
                hpMod += dice.bonus;
            }
        }

        maxhp = Math.max(1,maxhp + hpMod);
        namedValues["max hp"]=maxhp||0;

        const {spellAttributes,summoner} = mon;
        if (spellAttributes) {
            namedValues["cast spell level"] = spellAttributes.spellLevel||0;
            namedValues["additional spell levels"] = spellAttributes.additionalSpellLevels||null;
            namedValues["caster spell save"] = spellAttributes.saveVal||null;
            namedValues["caster spell attack"] = spellAttributes.attackRoll||null;
            namedValues["spellcasting mod"] = spellAttributes.spellcastingMod||null;
        }
        if (summoner) {
            Object.assign(namedValues, summoner);
        }

        this.spellSlots = [0,0,0,0,0,0,0,0,0];
        if (fTemplate){
            this.spellcastingAbility = fTemplate.abilityDC||"cha";
            let spellcast;
            let pactInfo;
            if (level) {
                switch (fTemplate.spellcaster) {
                    case"full":
                        spellcast = fullSpellInfo[level-1].spellSlots;
                        break;
                    case "half":
                        spellcast = halfSpellInfo[level-1].spellSlots;
                        break;
                    case "halfplus":
                        spellcast = halfPlusSpellInfo[level-1].spellSlots;
                        break;
                    case "third":
                        spellcast = thirdSpellInfo[level-1].spellSlots;
                        break;
                    case "pact":
                        pactInfo = pactSpellInfo[level-1];
                        break;
                    case "halfpact":
                        pactInfo = halfPactSpellInfo[level-1];
                        break;
                    case "thirdpact":
                        pactInfo = thirdPactSpellInfo[level-1];
                        break;
                }
            }
            if (spellcast) {
                this.spellSlots = spellcast.concat([]);
            } else if (pactInfo) {
                this.usePactCasting=true;
                this.spellSlots[pactInfo.pactLevel] = pactInfo.pactSlots;
            }
        } else {
            this.spellcastingAbility = "cha";
        }
        for (let sl=0; sl<9; sl++) {
            this.spellSlots[sl] = (this.spellSlots[sl]||0)+(addSpellSlots[sl]||0);
        }

        this.maxHP=maxhp;
        this.hpMod = hpMod;
        this.senses=senses;
        this.skills = skills;
        this.proficiency = proficiency;
        this.damageBonus = damageBonus;
        this.namedValues = namedValues;
        this.namedUsageMax = namedUsageMax;
        this.allSavingThrowBonus=savingThrowBonus;
        this.customMods = customMods;

        this.initiativeDice=initiativeDice;
        this.saveDice=saveDice;
        this.skillDice=skillDice;
        this.attackDice=attackDice;
        this.spellDice=spellDice;
        this.abilitiesDice=abilitiesDice;

        this.sizeAdjust=sizeAdjust;

        //console.log("values", namedValues, namedUsageMax);

        this.vulnerable = Object.keys(vulnerable).sort().join(", ")||"";
        this.selectedVulnerable = vulnerable;
        this.resist = Object.keys(resist).sort().join(", ")||"";
        this.selectedResist = resist;
        this.immune = Object.keys(immune).sort().join(", ")||"";
        this.selectedImmune = immune;
        this.conditionImmune = Object.keys(conditionImmune).sort().join(", ")||"";
        this.selectedConditionImmune = conditionImmune;
        this.languages = Object.keys(languages).sort().join(", ")||"";
        this.selectedLanguages = languages;

        this.addSpellSlots=addSpellSlots;
        this.spellAttackBonus=spellAttackBonus;
        this.spellDCBonus = spellDCBonus;

        this.acBonus=acBonus;
        this.noArmorBonus=noArmorBonus;
        this.noArmorShieldBonus=noArmorShieldBonus;
        this.lightArmorBonus =lightArmorBonus;
        this.mediumArmorBonus =mediumArmorBonus;
        this.heavyArmorBonus =heavyArmorBonus;
        this.anyArmorBonus =anyArmorBonus;

        this.baseACs = baseACs;
        this.mediumArmorMax = mediumArmorMax;
        this.computeAC(armorInfo);

        this.speedBonus=speedBonus;
        this.noArmorSpeedBonus=noArmorSpeedBonus;
        this.noArmorShieldSpeedBonus=noArmorShieldSpeedBonus;
        this.lightArmorSpeedBonus=lightArmorSpeedBonus;
        this.mediumArmorSpeedBonus=mediumArmorSpeedBonus;
        this.speed=this.computeSpeed(armorInfo, speed);

        this.unarmedAttacks = unarmedAttacks;

        this.passiveInvestigate=0;
        this.passiveInsight=0;

        this.baseSize = this.state.size || "M";
        if (this.crow && this.crow.size) {
            this.baseSize = this.crow.size;
        }
        this.passive = 10 + this.skills["Perception"].modifier + perceptionBonus;
        this.stealth = 10 + this.skills["Stealth"].modifier + stealthBonus;
        this.initiativeBonus = this.abilities["dex"].modifier + proficiencyBonus(proficiency, this.abilities["dex"].checkProficiency) + initiativeBonus;

        //console.log("computed state", this);
    }

    getAllModifiers(modifiers) {
        const t=this;
        const {companionMod,companion} = this.state;

        modifiers.derived = getModsFromMonster(this.state);
        if (this.includeConditions) {
            this.addConditionModifiers(modifiers);
            if (companionMod && companion) {
                modifiers.companion = companionMod;
            }
        }


        if (this.includeConditions) {
            const mod = {};
            this.traverseFeatures(function (params) {
                const {feature, optionFeature, noOption, fid, options, underActive, level} = params
                const saveMissing = mod.missingConfig;
                t.updateModFromFeature(noOption?optionFeature:feature, mod, options, fid, false, level);
                if (underActive) {
                    mod.missingConfig = saveMissing;
                }
            }, ["fTemplate"]);

            modifiers.fTemplate=mod;
            this.missingTemplateConfig = !!modifiers.fTemplate.missingConfig;
        }

        //console.log("modifiers", modifiers);
    }

    getExtensionVal(extName, oName) {
        if ((oName||"").toLowerCase().trim() == "cast spell level") {
            return this.state.spellAttributes?.spellLevel||0;
        }
        return super.getExtensionVal(extName, oName);
    }

    computeAC(armorInfo) {
        let ac;
        let acAdjust = 0;
        const baseACs = this.baseACs;
        const noShield = !armorInfo.shield;
        const noArmor = !armorInfo.armorType;
        let alternateBaseAc=false;


        if (noArmor) {
            let bestAC = armorInfo.ac;
            if (!noShield) {
                bestAC-=2;
                // shield bonus added back
            }
            for (let i in baseACs) {
                const bac = baseACs[i];
                if (bac.allowShield || noShield) {
                    let calcAC = bac.ac;
                    for (let x in (bac.ability||[])) {
                        calcAC += this.getAbility(bac.ability[x]).modifier||0;
                    }
                    for (let x in (bac.halfAbility||[])) {
                        calcAC += Math.floor((this.getAbility(bac.halfAbility[x]).modifier||0)/2);
                    }
                    if (calcAC > bestAC) {
                        bestAC = calcAC;
                        alternateBaseAc= true;
                    }
                }
            }

            ac = bestAC;
            acAdjust +=this.noArmorBonus;
            if (noShield) {
                acAdjust += this.noArmorShieldBonus;
            } else {
                acAdjust+=2;
            }
        } else {
            ac = armorInfo.ac;
            
            if (armorInfo.type == "LA") {
                acAdjust += this.lightArmorBonus;
            } else if (armorInfo.type == "MA") {
                acAdjust += this.mediumArmorBonus;
            } else if (armorInfo.type == "HA") {
                acAdjust += this.heavyArmorBonus;
            }

            acAdjust += this.anyArmorBonus;
        }
      
        acAdjust += this.acBonus;

        this.ac = ac + acAdjust;
        this.acAdjust = acAdjust;
        this.alternateBaseAc = alternateBaseAc;
    }

    computeSpeed(armorInfo, baseSpeed) {
        const noArmor = !armorInfo.type;
        const noShield = !armorInfo.shield;
        let speedBonus = this.speedBonus||0;
        const newSpeed = Object.assign({}, baseSpeed);

        if (noArmor) {
            speedBonus += (this.noArmorSpeedBonus+this.lightArmorSpeedBonus + this.mediumArmorSpeedBonus);
            if (noShield) {
                speedBonus += this.noArmorShieldSpeedBonus;
            }
        } else {
            if (armorInfo.type == "LA") {
                speedBonus += (this.lightArmorSpeedBonus + this.mediumArmorSpeedBonus);
            } else if (armorInfo.type == "MA") {
                speedBonus += this.mediumArmorSpeedBonus;
            }
        }

        if (speedBonus) {
            for (let i in newSpeed) {
                if (newSpeed[i] && newSpeed[i].number) {
                    newSpeed[i] = {number:Number(newSpeed[i].number)+speedBonus};
                }
            }
        }

        return newSpeed;
    }

    get displayName() {
        return this.getProperty("displayName");
    }

    get initiative() {
        return this.getProperty("initiative");
    }

    set initiative(v) {
        this.setProperty("initiative", v);
    }

    get str() {
        return this.getAbility("str").score;
    }
    get dex() {
        return this.getAbility("dex").score;
    }
    get con() {
        return this.getAbility("con").score;
    }
    get int() {
        return this.getAbility("int").score;
    }
    get wis() {
        return this.getAbility("wis").score;
    }
    get cha() {
        return this.getAbility("cha").score;
    }

    get conditions() {
        return this.getProperty("conditions");
    }

    set conditions(v) {
        return this.setProperty("conditions",v);
    }

    get curHP() {
        return this.hp;
    }

    restFeatures(long) {
        const t=this;
        const fList = [];
        const eList = [];
        const cList = [];

        this.traverseFeatures(function (params) {
            const {options, level}=params;
            let e = params.feature;
            let add = false;
            if (e.usage && (long || !e.usage.longRest) && !(e.usage.longRest <0) && (e.usage.restore != "none")) {
                const usage=e.usage;
                let max=0;

                if (usage.calcCount) {
                    const c = t.replaceMetawords(usage.calcCount);
                    if (!isNaN(c)) {
                        max = Number(c);
                    }
                } else if (usage.baseCount) {
                    max=usage.baseCount;
                } else if (usage.levelsCount) {
                    max = usage.levelsCount[level-1];
                } else if (usage.ability) {
                    max = t.getAbility(usage.ability).modifier+ (usage.abilityBonus||0);
                    if (max < 1) {
                        max=1;
                    }
                } else if (usage.proficiency) {
                    max = t.proficiency+(usage.abilityBonus||0);
                    if (max < 1) {
                        max=1;
                    }
                }

                if (max) {
                    add=true;
                }
            }
            if (resetFeatureSpells(e, params.fid, params.usageId, options)) {
                add=true;
            }
            if (add) {
                if (e.name || !params.typeValue) {
                    fList.push(e);
                } else {
                    const ne = Object.assign({}, e);
                    ne.name = params.typeValue.displayName;
                    fList.push(ne);
                }
            }
        });

        return {features:fList};

        function resetFeatureSpells(feature, baseName, optionsBase, options) {
            const selectedSpells = feature.pickedSpells||[];
            const castableSpells = feature.castableSpells||{};
            let add = false;
            for (let i in selectedSpells) {
                const s = selectedSpells[i];
                if (s.level) {
                    add=true;
                }
            }
            if (long || feature.recoveryType=="short") {
                for (let i in castableSpells) {
                    const s = castableSpells[i];
                    if (s.level) {
                        add=true;
                    }
                }
            }

            if (feature.spellPick && (long || feature.spellPick.recoveryType=="short")) {
                const spellPick = (options||{})[baseName+".spellPick"]||[];
                for (let i in spellPick) {
                    const s = spellPick[i];
                    if (s.level) {
                        add=true;
                    }
                }
            }
            return add;
        }
    }


    rest(long) {
        super.rest(long);
        const t=this;

        const props = {};

        if (this.usages) {
            const newUsages = [];
            const usages = this.usages;
            for (let i in usages) {
                const u = Object.assign({}, usages[i]);
                if (long || !u.longrest) {
                    if (!u.hidden) {
                        u.current=u.maximum||1;
                        newUsages.push(u);
                    }
                } else {
                    newUsages.push(u);
                }
            }
            props.usages = newUsages;
        }
    
        const maxHP = this.maxHP;
        let hp = this.hp;

        if (long) {
            hp = maxHP;
        } else {
            hp = (hp||0) + Math.trunc((maxHP||1)/2);
            hp = Math.min(hp, maxHP);
        }
        props.hp = hp;
    
        this.setProperty(props);
    }


    removeFeatureCompanions() {

    }

    traverseFeatures(callback, types, cclass, ignoreRestrictions) {
        const extraRoot = {};

        if (!types || types.includes("conditions")) {
            const conditions = this.conditions;
            if (conditions) {
                for (let c in conditions) {
                    const cond = conditions[c];
                    if (cond) {
                        if ((typeof cond == "object")) {
                            const conditionInfo = campaign.getCustom("Conditions", cond.selectedCondition);
                            if (conditionInfo) {
                                this.walkFeatures(callback,conditionInfo.features||[],"cond."+c, "cond."+c, this.level, {}, "custom", conditionInfo, {type:"Conditions", name:cond.selectedCondition},false, extraRoot,ignoreRestrictions);
                            }
                            if (cond.feature) {
                                this.walkFeatures(callback,[cond.feature],"fcond."+c, "fcond."+c, this.level, {}, "embed", null, null,false, extraRoot,ignoreRestrictions);
                            }
                        }
                    }
                }
            }
        }

        if (!types || types.includes("fTemplate")) {
            const {setFeature} = this.state;
            const fTemplate = campaign.getCustom(fTemplateName,this.state.fTemplate);
            if (fTemplate) {
                extraRoot.type = "fTemplate";
                extraRoot.val = fTemplate;

                this.walkFeatures(callback,fTemplate.features||[],"f","f", this.level, this.featureOptions, "fTemplate", fTemplate, fTemplate.name,false, extraRoot,ignoreRestrictions);
            }
            if (setFeature) {
                extraRoot.type = "setFeature";
                extraRoot.val = setFeature;

                this.walkFeatures(callback,[setFeature],"f","f", this.level, this.featureOptions, "setFeature", setFeature, setFeature.name||"",false, extraRoot,ignoreRestrictions);
            }
        }

    }

    damageHeal(adjust) {
        let temphp = this.temphp;
        let hp=this.hp;

        if (adjust > 0) {
            hp = hp+adjust;
            if (hp > this.maxhp) {
                hp = this.maxhp;
            }
        } else {
            if (temphp) {
                if ((temphp + adjust) < 0) {
                    adjust = adjust + temphp;
                    temphp = 0;
                } else {
                    temphp = temphp+adjust;
                    adjust = 0;
                }
            }
            hp = hp + adjust;
            if (hp <= 0){
                hp = 0;
            }
        }

        let setProps = {hp, temphp};
        this.setProperty(setProps);
    }

    get tokenArt() {
        return this.getProperty("tokenArt");
    }

    get d20Bonuses() {
        return this.getProperty("d20Bonuses");
    }

    get usages() {
        return this.getProperty("usages");
    }

    get featureOptions() {
        return this.getProperty("featureOptions")||{};
    }

    setProperty(prop, v, p2, v2, p3, v3) {
        const t=this;
        const mon = Object.assign({}, this.state);
        const crow = this.crow?Object.assign({}, this.crow):null;
        const unique = mon.unique || mon.npc;
        let updateMon, updatedCrow;

        //console.log("set property", prop, v, p2,v2,p3,v3, !!this.onChangeMon, !!this.crow, !!this.onChangeCrow);

        if (typeof prop == "object") {
            for (let i in prop) {
                intSetProp(i, prop[i]);
            }
        } else {
            intSetProp(prop,v);
            if (p2) {
                intSetProp(p2,v2);
            }
            if (p3) {
                intSetProp(p3,v3);
            }
        }

        if (updateMon) {
            if (this.onChangeMon) {
                if (typeof this.onChangeMon != "string") {
                    this.onChangeMon(mon);
                }
            } else {
                campaign.updateCampaignContent((campaign.isCampaignGame()&&unique)?"npcs":"monsters", mon);
            }
        }
        if (updatedCrow) {
            this.crow = crow;
            if (this.onChangeCrow) {
                this.onChangeCrow(crow);
            } else {
                this.updatedCrow = true;
            }
        }
        this.state = mon;
        this.computeValues();

        function intSetProp(prop,val) {
            switch (prop) {
                case "tokenArt": {
                    if (mon.tokenArt) {
                        let artList = mon.artList||[];
                        if (!artList.includes(mon.tokenArt)) {
                            artList = [mon.tokenArt].concat(artList);
                            intSetProp("artList",artList)
                        }
                    }
                    if (mon.tokenUrl) {
                        intSetProp("tokenUrl", null);
                    }
                    break;
                }
                case "ac":{
                    // don't allow ac update if base AC not active
                    if (t.alternateBaseAc) {
                        console.log("alternate ac ignoring")
                        return null;
                    }
                    // correct for the modifiers on the ac
                    val = val-t.acAdjust;
                    break;
                }
                case "maxHP":{
                    val = val-t.hpMod;
                    if (unique) {
                        const hp = Object.assign({}, mon.hp||{});
                        hp.maxHP = val;
                        mon.hp = hp;
                        updateMon=true;
                        return;
                    }
                    break;
                }
                case "hp":
                case "curHP":{
                    if (unique) {
                        const hp = Object.assign({}, mon.hp||{});
                        hp.curHP = val;
                        mon.hp = hp;
                        updateMon=true;
                        return;
                    }
                    prop="hp";
                    break;
                }
            }

            if (crow && ((!unique && ["featureOptions","ac", "hp","maxHP", "temphp", "size", "usage", "usages", "conditions", "tokenArt", "displayName"].includes(prop)) || (prop=="initiative"))) {
                if (prop=="displayName") {
                    prop = "name";
                }
                crow[prop]=val;
                updatedCrow=true;
                return;
            }

            switch (prop) {
                case "ac":{
                    const ac = [{ac:val, from:[t.armorInfo.extra||null]}];
                    val = ac;
                    //console.log("set ac", ac);
                    break;
                }
            }

            updateMon = true;
            mon[prop]=val;
        }
    }


    getProperty(prop) {
        const crow = this.crow;
        const mon = this.state;
        const unique = mon.unique || mon.npc;

        if (crow) {
            switch (prop) {
                case "initiative":
                    if (!unique) {
                        return crow.initiative;
                    }
                    break;
                case "conditions":
                    if (!unique) {
                        return crow.conditions;
                    }
                    break;
                case "curHP":
                case "hp":
                    if (!unique && (crow.hp || crow.hp===0)) {
                        return crow.hp
                    }
                    break;
                case "temphp":
                    if (!unique) {
                        return crow.temphp;
                    }
                    break;
                case "usages":{
                    if (!unique) {
                        return crow.usages||mon.usages||[];
                    }
                    break;
                }
                case "usage":{
                    if (!unique) {
                        return crow.usage||mon.usage||{};
                    }
                    break;
                }
                case "featureOptions":{
                    if (!unique) {
                        return crow.featureOptions||mon.featureOptions||{};
                    }
                    break;
                }
                case "tokenArt":{
                    return (!unique && crow.tokenArt) || mon.tokenArt;
                }
                case "temphp":{
                    return (!unique && crow.temphp) || mon.temphp;
                }
                case "displayName":{
                    return (!unique && crow.name) || mon.displayName;
                }
            }
        }

        switch (prop) {
            case "size":
                return this.size;
            case "ac":
                return this.ac;
            case "size":
                return this.size;
            case "maxHP":
                return this.maxHP;
            case "curHP":
            case "hp":
                if (!unique) {
                    return (Number(mon.hp?.average||0)+this.hpMod)||1;
                }
                return Number(mon.hp?.curHP||0);
            case "d20Bonuses":
                return {initiativeDice:this.initiativeDice,
                    saveDice:this.saveDice,
                    skillDice:this.skillDice,
                    attackDice:this.attackDice,
                    spellDice:this.spellDice
                }
        }

        return mon[prop]||null;
    }   
}

function findFeatures(root,rootName) {
    const features = [];
    for (const node of root.childNodes) {
        addNode(node);
    }
    //console.log("features", features);
    return features;

    function addNode(node) {
        if (!["UL","OL"].includes(node.nodeName)) {
            let name=rootName||null;
            rootName=null;
            let first = node.firstChild;
            const text = node.textContent;
            if (first && ["I","B","STRONG"].includes(first.nodeName)) {
                const fText = first.textContent;
                if (text != fText) {
                    name = fText.replace(/(\.|\().*/,"").trim();
                }
            }
            features.push({name, node, first:first && first.nodeName, nodeName:node.nodeName, text:node.textContent});
        } else {
            for (const inode of node.childNodes) {
                addNode(inode);
            }
        }
    }
}


function findDamageType(node) {
    const after = node.nextSibling;
    if (after) {
        const text = after.textContent;
        if (text) {
            let [dmg] = text.replace(")","").trim().split(" ");
            if (dmg) {
                dmg = dmg.toLowerCase();
                if (damageTypesList.includes(dmg)) {
                    return dmg;
                }
            }
        }
    }
    return null;
}

function findFixedDamage(node) {
    for (let i=0; i<3; i++) {
        const after = node.nextSibling;
        if (after) {
            const text = after.textContent;
            if (text) {
                const pos = text.search(/\s?\d+\s/);
                if (pos >=0) {
                    let [dmg,dmgType] = text.substr(pos).trim().split(" ");
                    dmgType = (dmgType||"").trim().toLowerCase();
                    if (damageTypesList.includes(dmgType)) {
                        return {dmg:Number(dmg), dmgType};
                    }
                }
            }
            node = after;
        } else {
            break;
        }
    }
    return null;
}

function addUses(node, used, total, onClick) {
    if (node) {
        // first remove old pips
        while (node.nextElementSibling?.classList && node.nextElementSibling.classList.contains("pip")) {
            node.nextElementSibling.remove();
        }
        let last=node;

        for (let i=0; i<total; i++) {
            const pip= document.createElement('span');
            pip.classList.add("pip","hoverhighlight","far", "ph--1",(i<used?"fa-times-circle":"fa-circle"));
            if (onClick) {
                pip.addEventListener("click",(i<used)?sub:add);
            }
            last.after(pip);
            last=pip;
        }
    }

    function add() {
        onClick(1);
    }
    function sub() {
        onClick(-1);
    }
}

function labelUses(node, id) {
    let num=0;
    let eachId, lastId, lastCount, lastUnit;

    internalLabel(node);

    function internalLabel(node) {
        let child = node.firstChild;
        while (child) {
            let next = child.nextSibling;
            if (child.nodeType==3) {
                const text = child.textContent;
                let [m] = text.match(/(\d+\s*.{0,30}\/\s*(day|short rest|short or long rest|long rest|rest)(\s*each)?|[1-9]\D\D[-\s]+(level|circle)\s+\(?\s*\d+\s+slots?|recharge\s*\d+\D+\d+|recharge\s*\d+|recharge.{4,30}rest|once per.{4,30}rest)/i)||[];
                if (m) {
                    const pos = text.indexOf(m);
                    let start = text.substr(0,pos);
                    let end = text.substr(pos+m.length);
                    const use = document.createElement('span');
                    let unit="long";
                    let count,level, die,min, each;
                    use.classList.add("monuses");
                    const d = use.dataset;
                    let assignId;
                    let split=true;
                    let useId = num?(id+"."+num):id;

                    eachId = null;
                    lastId = null;
                    if ((/recharge/i).test(m)) {
                        if (!(/rest/i).test(m)) {
                            const [d1,d2] = m.match(/\d+/g)||[];
                            d.unit = "recharge";
                            d.min = Number(d1);
                            d.die = Number(d2||d1);
                        } else {
                            d.unit = (/short/i).test(m)?"short":"long";
                            d.count=1;
                        }
                        assignId=true;
                    } else if ((/once/i).test(m)) {
                        d.unit = (/short/i).test(m)?"short":"long";
                        d.count=1;
                        assignId=true;
                    } else if ((/(level|circle)/i).test(m)) {
                        let [level,count] = m.match(/\d+/ig);
                        d.unit="slots";
                        
                        level = Number(level);
                        count = Number(count);

                        d.level = level;
                        d.count = count;
                        m = m.replace(/\s*\d+\s+slots?/i, "");
                    } else {
                        const [count] = m.match(/\d+/i);
                        const unit = (/short/i).test(m)?"short":"long";
                        if ((/each/i).test(m)) {
                            num++;
                            eachId = useId;
                            lastUnit=unit;
                            lastCount = Number(count);
                            // don't split since it's an each
                            split=false;
                        } else {
                            end = m.replace(count,"")+end;
                            m="";
                            assignId=true;
                            //console.log("count", unit, count, start,"-", m, "-",end)
                        }
                        d.unit= unit;
                        d.count = Number(count);
                    }
                    if (split) {
                        use.textContent = m;
                        if (assignId) {
                            d.useId = useId;

                            lastId = useId;
                            lastCount = d.count;
                            lastUnit = d.unit;
                            num++;
                        }
                        if (start.length) {
                            node.insertBefore(new Text(start), child);
                        }
                        node.insertBefore(use, child);
                        child.textContent = end;
                    }
                }
            } else if (child.nodeName=="A") {
                const d = child.dataset;
                if (eachId) {
                    d.eachId = eachId;
                    d.useUnit = lastUnit;
                    d.useCount = lastCount;
                    child.classList.add("eachuses");
                } else if (lastId) {
                    d.useId = lastId;
                    d.useUnit = lastUnit;
                    d.useCount = lastCount;
                }
            } else {
                internalLabel(child);
            }
            child=next;
        }
    }
}


function findSpellcasting(text) {
    if (text) {
        const [save] = text.match(/spell.*\s(str|dex|con|wis|int|cha)/i)||[];
        if (save) {
            return save.substr(-3).toLowerCase();
        }
    }
    return null;
}

function findSpellcastingLevel(text) {
    if (text) {
        const [sc] = text.match(/\d+.{2,30}level spellcaster/i)||[];
        if (sc) {
            const [level] = text.match(/\d+/);
            return Number(level);
        }
    }
    return 0;
}

function findSpellAttack(text) {
    if (text) {
        const [sc] = text.match(/(\+|\-)?\d+ to hit with spell attacks/i)||[];
        if (sc) {
            const [numText] = sc.split(" ");
            if (numText && !isNaN(numText)) {
                return Number(numText);
            }
        }
    }
}

function findSpellSave(text) {
    if (text) {
        const [save] = text.match(/spell save dc\s+\d+/i)||[];
        if (save) {
            const [dc] = save.match(/\d+/);

            return Number(dc);
        }
    }
    return null;
}


function findSave(text) {
    if (text) {
        const [save] = text.match(/dc\s+\d+\s+(str|dex|con|wis|int|cha)/i)||[];
        if (save) {
            const [dc] = save.match(/\d+/);

            return {dc:Number(dc), ability:save.substr(-3).toLowerCase()};
        }
    }
    return null;
}

function findAttackType(text) {
    if ((/ranged weapon attack/i).test(text)) {
        return "ranged";
    }
    if ((/melee weapon attack/i).test(text)) {
        return "melee";
    }
    if ((/spell attack/i).test(text)) {
        return "spell";
    }
    if ((/attack/i).test(text)) {
        return "melee";
    }
}

function findAttack(root, feature,clickDamage,clickAttack,clickRoll) {
    const attack={};
    let noMoreDamage;

    const list = (root.getElementsByClassName&&root.getElementsByClassName("dodieroll")) || [];
    for (let i=0; i<list.length; i++) {
        const node = list[i];
        
        const tc = node.textContent;
        if (tc.toLowerCase().includes("d")) {
            // check to see if damage
            const dmgType = findDamageType(node);
            if (dmgType) {
                if (!noMoreDamage) {
                    const dmgInfo = {dmg:tc.replace(/\s+/g,""), dmgType};
                    const prev = attack.damages && node.previousSibling;
                    if (prev && (/\s(plus|\+|and)\s/i).test(prev.textContent)) {
                        attack.damages.push(dmgInfo);
                        if (attack.altDamages) {
                            attack.altDamages.push(dmgInfo);
                        }
                    } else if (!attack.damages) {
                        attack.damages=[dmgInfo];
                    } else if (!attack.altDamages) {
                        attack.altDamages = [dmgInfo];
                    }
                }
                if (clickDamage) {
                    node.addEventListener("click",clickDamage.bind(null, tc, dmgType,feature ));
                }
            } else if (clickRoll) {
                node.addEventListener("click",clickRoll.bind(null, tc, feature ));
            }
        } else if (noMoreDamage || attack.attackRoll) {
            // already found attack ignore rest of rolls
            if (clickRoll) {
                node.addEventListener("click",clickRoll.bind(null, tc, feature ));
            }
            noMoreDamage=true;
        } else {
            attack.attackRoll = tc;
            if (!attack.damages) {
                const damage = findFixedDamage(node);
                if (damage) {
                    attack.damages=[damage];
                }
            }
            if (clickAttack) {
                node.addEventListener("click",clickAttack.bind(null, attack,feature ));
            }
        }
    }
    return attack;
}


function updateMonsterAttributes(oldV, newV) {

    if ((oldV.crsort != newV.crsort) ||
        (oldV.str != newV.str) ||
        (oldV.dex != newV.dex) ||
        (oldV.con != newV.con) ||
        (oldV.wis != newV.wis) ||
        (oldV.int != newV.int) ||
        (oldV.cha != newV.cha) ||
        (oldV.proficiency != newV.proficiency)
    ) {
        const oMon = new MonObj(oldV);

        // need to update dependent attributes
        const odProf = defaultProficiency(oldV);
        const ndProf = defaultProficiency(newV);

        if (newV.proficiency && (oldV.proficiency == newV.proficiency)) {
            // if specific prof then adjust if default prof changed
            newV.proficiency += (ndProf-odProf);
        }
        const oProf = oldV.proficiency || odProf;
        const nProf = newV.proficiency || ndProf;
        const profAdjust = (nProf-oProf);
        const abilityAdjust = {};
    
        for (let astr of stdvalues.abilities) {
            const omod = Math.floor(Number(oldV[astr]||10)/2)-5;
            const nmod = Math.floor(Number(newV[astr]||10)/2)-5;
            abilityAdjust[astr] = nmod-omod;
        }

        if (newV.save) {
            const newSave = Object.assign({}, newV.save);
            for (let astr in newSave) {
                if (newSave[astr] == (oldV.save||{})[astr]) {
                    if (oMon.abilities[astr].proficiency) {
                        newSave[astr] = Number(newSave[astr])+ profAdjust;
                    }
                    newSave[astr] = Number(newSave[astr])+ abilityAdjust[astr];
                }
            }
            newV.save = newSave;
        }

        if (newV.skill) {
            const skills = oMon.skills;
            const newSkill=Object.assign({}, newV.skill);
            for (let sStr in newSkill) {
                let skillInfo;
                for (let s in skills) {
                    if (s.toLowerCase() == sStr.toLowerCase()) {
                        skillInfo = skills[s];
                    }
                }
                if (skillInfo) {
                    if (newSkill[sStr] == (oldV.skill||{})[sStr]) {
                        if (skillInfo.proficiency) {
                            newSkill[sStr] = Number(newSkill[sStr])+ profAdjust;
                        }
                        newSkill[sStr] = Number(newSkill[sStr])+ abilityAdjust[skillInfo.ability];
                    }
                }
            }
            newV.skill = newSkill;
        }

        const nMon = new MonObj(newV);

        {
            // check to see if AC needs to be adjusted
            let maxBonus=20;

            switch (nMon.armorInfo.armorType) {
                case "MA":
                    maxBonus=2;
                    break;
                case "HA":
                    maxBonus=0;
                    break;
            }
            let adjust = Math.min(nMon.abilities.dex.modifier, maxBonus) - Math.min(oMon.abilities.dex.modifier, maxBonus);
            if (adjust != 0) {
                if (Array.isArray(newV.ac)) {
                    const newAc = [];
                    for (let ac of newV.ac ) {
                        if (ac.ac) {
                            const sac = Object.assign({}, ac);
                            sac.ac = Number(sac.ac)+adjust;
                            newAc.push(sac);
                        } else {
                            newAc.push(Number(ac)+adjust);
                        }
                    }
                    newV.ac = newAc;
                } else  {
                    newV.ac = Number(newV.ac)+adjust;
                }
            }
        }
        
        if (newV.trait) {
            newV.trait = updateTextActions(newV.trait, oMon, nMon)
        }
        if (newV.action) {
            newV.action = updateTextActions(newV.action, oMon, nMon)
        }

        if (newV.bonusaction) {
            newV.bonusaction = updateTextActions(newV.bonusaction, oMon, nMon)
        }
        if (newV.reaction) {
            newV.reaction = updateTextActions(newV.reaction, oMon, nMon)
        }
        if (newV.legendary) {
            newV.legendary = updateTextActions(newV.legendary, oMon, nMon)
        }
    }
}

const dieMatch =/^([\s\+\-dD\d\(\)]+)$/;
function updateTextActions(entry, oMon, nMon) {
    if (!entry || (entry.length==0)) {
        return entry;
    }
    const html = entry.length==1?entry[0].html:entry.html;
    if (!html) {
        return entry;
    }
    const wrapper= document.createElement('div');
    wrapper.innerHTML= html;
    const features = findFeatures(wrapper);
    for (let f of features) {
        const node = f.node;
        const text = node.textContent;
        if (text) {
            let spellAbility = findSpellcasting(text);
            let attack = findAttackType(text);

            //console.log("update text actions", f.name, attack, spellAbility, text);
            switch (attack) {
                default:
                    processDCs(node,getDCSubs(oMon, nMon, ["con","str","dex","cha"]));
                    processDieRolls(node, getBonusSubs(oMon, nMon,["str","dex"],true), getBonusSubs(oMon, nMon,["str","dex"],false));
                    break;
                case "melee":
                    processDCs(node,getDCSubs(oMon, nMon, ["con","str","dex"]));
                    processDieRolls(node, getBonusSubs(oMon, nMon,["str","dex"],true), getBonusSubs(oMon, nMon,["str","dex"],false));
                    break;
                case "ranged":
                    processDCs(node,getDCSubs(oMon, nMon, ["dex","con","str"]));
                    processDieRolls(node, getBonusSubs(oMon, nMon,["dex"],true), getBonusSubs(oMon, nMon,["dex"],false));
                    break;
                case "spell":
                    const abilities = spellAbility?[spellAbility]:["cha","wis","int"];
                    processDCs(node,getDCSubs(oMon, nMon,abilities));
                    processDieRolls(node, getBonusSubs(oMon, nMon,abilities,true), []);
                    break;
            }
        }
    }
    return {html:wrapper.innerHTML, type:"html"};
}

function processDieRolls(rootNode, attackSubsList, damageSubsList) {
    const {getAverageFromDice,getDiceFromString} = require('../src/diceroller.jsx');
    const tags = ["b","strong"];
    if (!rootNode.getElementsByTagName) {
        return;
    }
    for (let t of tags) {
        const list =rootNode.getElementsByTagName(t);
        for (let i=0; i<list.length; i++){
            const node = list[i];
            if (!node.children?.length) {// ignore if nested elements that aren't just text.
                const inner = (node.textContent||"");
                if (inner && inner.match(dieMatch)) {
                    const damageRoll = inner.match(/d/i);
                    const dmgType = damageRoll?findDamageType(node):null;
                    const replaceList = damageRoll?damageSubsList:attackSubsList;

                    for (let r of replaceList) {
                        if (r.old != r.new) {
                            let justAppend;

                            const pos = inner.lastIndexOf(r.old);
                            if ((pos<0) && damageRoll && ["bludgeoning","piercing","slashing"].includes(dmgType) && (Number(r.old)===0) && !(/[\+-]/.test(inner))) {
                                // if sub not found and damage roll and a damage type that would get a bonus and no bonus with old and no bonus in roll then just add the bonus
                                justAppend = true;
                            }
                            if ((pos>=0)||justAppend) {
                                const newV=(damageRoll && ["+0","+ 0"].includes(r.new))?"":r.new;
                                const text = justAppend?(inner+newV):(inner.substring(0,pos) + newV + inner.substring(pos+r.old.length));

                                if (damageRoll && (inner != text)) {
                                    const oldAvg = getAverageFromDice(getDiceFromString(inner));
                                    const newAvg = getAverageFromDice(getDiceFromString(text));

                                    const prev = node.previousSibling;
                                    if (prev && prev.textContent) {
                                        prev.textContent = prev.textContent.replace(oldAvg, newAvg)
                                    }
                                }
                                node.textContent=text;
                                break;
                            }
                        }
                    }
                }
            }
        }
    }
}

function processDCs(node, dcList) {
    if (node.nodeName=="#text"){
        const text = node.textContent;
        for (let r of dcList) {
            if ((r.old != r.new)&& text.includes(r.old)) {
                node.textContent = text.replace(r.old, r.new);
                return;
            }
        }
        return;
    }
    for (let subnode of node.childNodes) {
        processDCs(subnode,dcList);
    }
}

function getBonusSubs(oMon, nMon, list,includeProf) {
    const oAbilities = oMon.abilities, nAbilities = nMon.abilities;
    const oProf = includeProf?oMon.proficiency:0, nProf=includeProf?nMon.proficiency:0;
    const subs = [];

    for (let a of list) {
        const o = oAbilities[a], n=nAbilities[a];
        subs.push({old:signedNum(o.modifier+oProf), new:signedNum(n.modifier+nProf)});
        subs.push({old:spacedSignedNum(o.modifier+oProf), new:spacedSignedNum(n.modifier+nProf)});
    }
    return subs;
}

function getDCSubs(oMon, nMon, list) {
    const oAbilities = oMon.abilities, nAbilities = nMon.abilities;
    const oProf = oMon.proficiency, nProf=nMon.proficiency;
    const subs = [];

    for (let a of list) {
        const o = oAbilities[a], n=nAbilities[a];
        subs.push({old:"DC "+(8+o.modifier+oProf), new:"DC "+(8+n.modifier+nProf)});
        subs.push({old:"DC"+(8+o.modifier+oProf), new:"DC"+(8+n.modifier+nProf)});
    }
    return subs
}

function spacedSignedNum(n) {
    return ((n<0)?"- ":"+ ")+Math.abs(n);
}

function getSpeedVals(speedVal, speedtype) {
    let speed;
    let notes;
    let monspeed;

    if (!speedVal) {
        return {number:0, condition:""};
    }

    if (typeof speedVal == 'object') {
        monspeed = speedVal[speedtype];
    } else {
        if (speedtype == 'walk') {
            monspeed = speedVal;
        } else {
            monspeed = "";
        }
    }

    if (typeof monspeed =='object') {
        speed = monspeed.number || "0";
        notes = monspeed.condition || "";
    } else {
        speed=monspeed || "";
        notes="";
    }
    return {number:speed, condition:notes};
}

function armorInfoFromMonAC(ac) {
    const acVal = Parser.acToStruc(ac);
    const text = acVal.extra;
    const acInfo = {};
    
    if (!text?.length || (text.match(/\s*shield\s*/i)==text) || text.match(/natural armor/i) || text.match(/mage armor/i)) {
        // no armor
    } else if (text.match(/(breastplate|chain shirt|half plate|hide|scale)/i)) {
        acInfo.armorType = "MA";
    } else if (text.match(/(chain|plate|ring|splint|steel)/i)) {
        acInfo.armorType = "HA";
    } else if (text.match(/(armor|leather|studded|brigadine)/i)) {
        acInfo.armorType = "LA";
    }
    if (text.match(/shield/i)){
        acInfo.shield=true;
    }
    acInfo.ac = Number(acVal.ac||10);
    if (text) {
        acInfo.extra = text;
    }
    return acInfo;
}

function defaultProficiency(mon) {
    const level = Math.min(30,Math.trunc(mon.crsort));
    return ((level>1)?(Math.trunc((level-1)/4) +2):2);
}

function getModsFromMonster(mon) {
    const mod={skills:{}, abilityScores:{}, speed:{},savingThrowBonusAbilities:{}};
    const abilities = {};
    let proficiency = defaultProficiency(mon);
    let {skill, save, passive, stealth} = mon;
    const monProficiency = Number(mon.proficiency||0);
    const skillVals =campaign.getAllSkillsWithAbilities();

    stdvalues.abilities.forEach(function(ability){
        const score = Number(mon[ability]||10);
        abilities[ability] = {
            score,
            modifier:Math.floor(score/2)-5
        };
    });

    if (monProficiency && (monProficiency != proficiency)) {
        mod.proficiencyBonus = monProficiency-proficiency;
        proficiency=monProficiency;
    }

    const {getPassive,getStealth} = require('../src/rendermonster.jsx');
    passive = passive?Number(passive):getPassive(mon);
    stealth = stealth?Number(stealth):getStealth(mon);

    if (!skill?.stealth) {
        skill = Object.assign({},skill||{});
        skill.stealth = stealth-10;
    } else {
        mod.stealthBonus =  stealth-10 -Number(skill.stealth);
    }

    for (let s in skill) {
        const skillInfo = skillVals.find(function(skill){
            return (skill.skill.toLowerCase() == s.toLowerCase());
        });

        if (skillInfo) {
            const v = Number(skill[s]||0);
            if (skillInfo) {
                const amod = abilities[skillInfo.mod].modifier;
                const skillMod = {modifier:v-amod};
                mod.skills[skillInfo.skill]=skillMod;
                if (v >= (amod+proficiency*2)) {
                    skillMod.proficiency = "expert";
                    skillMod.modifier -= proficiency*2;
                } else if (v >= (amod+proficiency)) {
                    skillMod.proficiency = "proficient";
                    skillMod.modifier -= proficiency;
                }
            }
        }
    }
    for (let s in save) {
        const v = Number(save[s]||0);
        s = s.toLowerCase();
        const sa = stdvalues.abilityLongNames[s] || s;
        if (stdvalues.abilityNames[s]) {
            const amod = abilities[sa].modifier;
            let modVal = v-amod;
            if (v >= (amod+proficiency*2)) {
                mod.abilityScores[sa] = {proficiency:"expert"};
                modVal -= proficiency*2;
            } else if (v >= (amod+proficiency)) {
                mod.abilityScores[sa] = {proficiency:"proficient"};
                modVal -= proficiency;
            }
            mod.savingThrowBonusAbilities[sa]={all:modVal};
        }
    }

    let pp = abilities.wis.modifier+10;

    switch (mod.skills?.Perception?.proficiency) {
        case "expert":
            pp += proficiency;
        case "proficient":
            pp += proficiency;
            break;
    }
    pp += (mod.skills?.Perception?.modifier ||0);
    if (passive) {
        mod.perceptionBonus = Number(passive)-pp;
    }

    const movements=["walk", "burrow", "climb", "fly", "swim"];
    for (let m of movements) {
        const speed = getSpeedVals(mon.speed, m);
        if (speed.number) {
            mod.speed[m]={number:Number(speed.number)};
        }
    }

    return mod;
}

export {
    MonObj,
    updateMonsterAttributes,updateTextActions,
    findFeatures,findDamageType,findFixedDamage,findSpellcasting,findSpellcastingLevel,findSave,findAttack,
    findSpellSave,
    findSpellAttack,
    findAttackType,
    addUses,labelUses,getModsFromMonster,
    fTemplateName
}