const React = require('react');
const ReactDOM = require('react-dom');
import Popover from '@material-ui/core/Popover';


class ShowTour extends React.Component {
    constructor(props) {
        super(props);
        this.state={};
    }

    componentDidMount() {
    }
  
    componentWillUnmount() {
    }

	render() {
        const {currentTour, step} = this.state;
        let popup;

        if (currentTour) {
            const curStep = currentTour[step];
            const lastStep = (step+1)==currentTour.length;
            let anchorReference="anchorPosition", 
            anchorEl,
            anchorOrigin={vertical:"center",horizontal:"center"},
            transformOrigin={vertical:"center",horizontal:"center"},
            backdropProps={};

            if (curStep.id) {
                const el = document.getElementById(curStep.id);
                if (el) {
                    backdropProps.anchorEl=el;
                    anchorEl=el;
                    anchorReference="anchorEl"
                    el.scrollIntoView({behavior:"instant", block:"center"});
                }
                switch (curStep.horizontal) {
                    case "left":
                        anchorOrigin.horizontal="left";
                        transformOrigin.horizontal="right";
                        break;
                    case "right":
                        anchorOrigin.horizontal="right";
                        transformOrigin.horizontal="left";
                        break;
                }
                switch (curStep.vertical) {
                    case "top":
                        anchorOrigin.vertical="top";
                        transformOrigin.vertical="bottom";
                        break;
                    case "bottom":
                        anchorOrigin.vertical="bottom";
                        transformOrigin.vertical="top";
                        break;
                }
            }

            popup = <Popover
                open
                anchorReference={anchorReference}
                anchorEl={anchorEl}
                anchorPosition={{left:window.innerWidth/2, top:window.innerHeight/2}}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                BackdropComponent={TourBackdrop}
                BackdropProps={backdropProps}
            >
                <div className="pl1 pv1 lightBackground darkText w7 flex items-start">
                    <div className="flex-auto tc f3">{curStep.title}</div>
                    <span className="pa1 f8 hoverhighlight fas fa-times" onClick={this.endTour.bind(this)}/>
                </div>
                <div className="f4 tc maxh4 w7 darkBackground lightText overflow-auto pa1">{curStep.description}</div>
                <div className="flex items-end w7 darkBackground lightText">
                    {step>0?<div className="fas fa-arrow-left f8 pa1 hoverhighlight" onClick={this.setStep.bind(this,step-1)}/>:
                        <div className="fas fa-arrow-left f8 pa1 clearText"/>
                    }
                    <div className="flex-auto f6 tc pa1">
                        Step {step+1} of {currentTour.length}
                    </div>
                    {lastStep?<div className="fas fa-check f8 pa1 hoverhighlight" onClick={this.endTour.bind(this)}/>:
                        <div className="fas fa-arrow-right f8 pa1 hoverhighlight" onClick={this.setStep.bind(this,step+1)}/>
                    }
                </div>

            </Popover>;
        }
        return <span>
            <span className="fas fa-question pa1 hoverhighlight" onClick={this.startTour.bind(this)}/>
            {popup}
        </span>;
    }


    endTour() {
        this.setState({currentTour:null});
    }

    setStep(step) {
        this.setState({step});
    }

    startTour() {
        this.setState({currentTour:testSteps, step:0})
    }
}

class TourBackdrop extends React.Component {
    constructor(props) {
        super(props);
        this.state={};
    }

    componentDidMount() {
    }
  
    componentWillUnmount() {
    }

	render() {
        const {anchorEl} = this.props;
        if (!anchorEl) {
            return <div className="h-100 w-100 fadedBackground"/>
        }
        const blocks=[];
        const width=window.innerWidth, height=window.innerHeight;
        const {left,right, top, bottom}=anchorEl.getBoundingClientRect();

        if (left > 0) {
            blocks.push(<div key="left" className="fadedBackground fixed" style={{top, left:0, width:left, height:bottom-top}}/>)
        }
        if (right <width) {
            blocks.push(<div key="right" className="fadedBackground fixed" style={{top, left:right, width:width-right, height:bottom-top}}/>)
        }
        if (top >=0) {
            blocks.push(<div key="top" className="fadedBackground fixed" style={{top:0, left:0, width, height:top}}/>)
        }
        if (bottom <height) {
            blocks.push(<div key="bottom" className="fadedBackground fixed" style={{top:bottom, left:0, width, height:height-bottom}}/>)
        }
        console.log("found blocks", blocks)
        return <div>{blocks}</div>;
    }
}

const testSteps=[
    {
        title:"Start",
        description:"Here is some text to show"
    },
    {
        title:"Characters",
        description:"Here is where you can view charactes or create a new one.",
        id:"tourCharacters",
        horizontal:"right",
        vertical:"top"
    },
    {
        title:"Campaigns",
        description:"This campaigns that you play or are the gamemaster.",
        id:"tourCampaign",
        vertical:"bottom",
        horizontal:"left"
    },
    {
        title:"Library",
        description:"Library shows books you created or that came with purchases",
        id:"tourLibrary",
        vertical:"top"
    },
    {
        title:"Reference",
        description:"You can view all of the entries for content you create or own.  View the entry type to create your own.",
        id:"tourReference",
        horizontal:"left"
    },
    {
        title:"Finish",
        description:"Here is some text to show"
    },
];

export {
    ShowTour
};