const React = require('react');
const ReactDOM = require('react-dom');
const EventEmitter = require('events'); 
const {campaign,sleep, globalDataListener,areSameDeep,areSameDeepInst,areSameDeepIgnore} = require('../lib/campaign.js');
const {displayMessage,snackMessage} = require('./notification.jsx');
import PopoutWindow from './react-popout.jsx';
import Popover from '@material-ui/core/Popover';
const {MapPickList} = require('./rendermaps.jsx');
import Paper from '@material-ui/core/Paper';
const {HoverMenu} = require( "./hovermenu.jsx");
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
const {getAnchorPos,maxLen,isMobile} = require('./stdedit.jsx');
const {getTokenInfo} = require('./encountermonsterlist.jsx');
const {ShowHandout,PickHandout} = require('./handouts.jsx');
const {MapDialog} = require('./rendermaps.jsx');
const {PinMenu} = require('./pins.jsx');
const {imageCache} = require('./imagecache.jsx');
import sizeMe from 'react-sizeme';
const {sizeScaleMap,colorContrastMap} = require('../lib/stdvalues.js');
import Konva from 'konva'

const testCallback=0;
const bgColor = "#fdf1dc";
const fogColor = "#282828";
const toolBackground = "#182026";
const toolStroke = "rgb(238, 231, 205)";
const toolHeight=34;
const skullUrl = "/scross.png";
const heartbeatUrl = "/heartbeat.png";
const gridBaseSize = 70;
const selWidth = 0.2;
const doubleSelWidth = 0.4;
const topToolbarHeight = 28;
const toolWidth = 28;
const selectHighlight = "rgba(64,64,64,0.2)";

class MapView extends React.Component {
    constructor(props) {
        super(props);
        this.loadAdventureViewFn = this.loadAdventureView.bind(this);
        this.state={mapInfo:null};
    }

    componentDidMount() {
        this.startCanvas();

        globalDataListener.onChangeCampaignContent(this.loadAdventureViewFn,"adventure");
        this.loadAdventureView();
    }

    componentWillUnmount() {
        this.map && this.map.destroy();
        this.map=null;
        this.combatants && this.combatants.destroy();
        this.combatants=null;

        globalDataListener.removeCampaignContentListener(this.loadAdventureViewFn,"adventure");
    }

    loadAdventureView() {
        const {campaignMode} = this.props;
        if (campaignMode && this.map) {
            const av = campaign.getAdventureView();
            const handouts = campaign.getHandouts();

            const ns = {};
    
            const handout = (handouts.showHandout && handouts.mru && handouts.mru.length)?handouts.mru[0]:null;
            if ((handouts.showHandout  && !this.state.lastShowHandout) || !areSameDeep(handout, this.state.lastShownHandout)) {
                ns.handout = handout;
                ns.lastShownHandout = handout;
                ns.lastShowHandout = handouts.showHandout;
            }
    
            this.cversion = av.cversion;
            this.setState(ns);
        }
    }

    componentDidUpdate(prevProps) {
        const {size, pointerX, pointerY, handout, mapName,variant,mapPos} = this.props;
        const {fixedHeight} = this.state;
        let {width,height}= (size||{});
        
        if (this.map) {
            if (mapName != prevProps.mapName) {
                this.map.setMap(mapName);
            }
            if (mapPos && (mapPos != prevProps.mapPos)) {
                this.map.setMap(mapPos.mapName, mapPos);
            }
            if ((prevProps.size.width != width) || (prevProps.size.height!=height) ) {
                this.calcFixedHeight();
                this.map.setDimensions(width, height);
            } else if (!fixedHeight) {
                this.calcFixedHeight();
            }
        }
        if ((prevProps.pointerX != pointerX) || (prevProps.pointerY != pointerY)) {
            this.map.setPointer(pointerX, pointerY);
        }
        if (handout != prevProps.handout) {
            this.setState({handout});
        }
    }

    calcFixedHeight() {
        const {size, variant} = this.props;
        if ((variant=="inline") && this.map) {
            const mapDimensions = this.map.mapDimensions;
            if (mapDimensions) {
                const setHeight = size.width * (mapDimensions.height||1)/(mapDimensions.width||1);
                this.setState({fixedHeight:setHeight});
            }
        }
    }

    startCanvas() {
        const {size, variant, campaignMode, playerView,mapName,mapPos} = this.props;
        const {width,height} = (size||{}); 
        this.map = new Map({
            container: this.contentRef,
            width,
            height,
            showCover:!["inline"].includes(variant),
            playerView,
            campaignMode,
            variant,
            mode:"move"
        });

        if (campaignMode) {
            this.combatants = new EncounterCombatants("campaign", playerView);

            this.map.setEncounterCombatants(this.combatants);
        }

        if (mapPos) {
            this.map.setMap(mapPos.mapName, mapPos);
        } else if (mapName) {
            this.map.setMap(mapName);
        }

        this.calcFixedHeight();
        // cleaned up on destroy
        this.map.events.on("tap", this.tapCombatant.bind(this));
        this.map.events.on("selection", this.selectChange.bind(this));
        this.setState({select:campaign.newUid()}); // force reload after setting map
    }

    render() {
        const {EditMapObject} = require('./objects.jsx');
        const {size,character,variant,className,onClick} = this.props;
        const {fixedHeight,showMapSettings,showEditObject,editObject,handout} = this.state;
        let {width,height} = size;
        const map = (this.map||{});
        const mode = (this.map||{}).mode;
        let cursor="";

        if (!["popout","inline"].includes(variant)) {
            switch (mode) {
                case "move":
//                    cursor = "cursor-all-scroll";
                    break;
                case "select":
                    cursor = "cursor-cell";
                    break;
            }
        }

        const topToolbar = this.getTopToolbar();

        return <div onContextMenu={onClick?null:function(e){e.preventDefault()}} className={"tourMap "+(className||"")} style={{height:fixedHeight||"100%", width:"100%", overflow:"hidden", position:"relative",backgroundColor:fogColor}} onClick={onClick}>
            {topToolbar}
            <div className={cursor} style={{position:"absolute", top:topToolbar?topToolbarHeight:0, left:0, width, height:topToolbar?height-topToolbarHeight:height}} ref={this.saveRef.bind(this)}>
            </div>
            {this.getSelectedToolbar()}
            {this.getZoom()}
            <EditMapObject open={showEditObject} object={editObject} onClose={this.handleEditObject.bind(this)}/>
            {showMapSettings?<MapDialog open={showMapSettings} name={map.mapInfo.name} onClose={this.showMapSettings.bind(this,false)}/>:null}
            {handout?<ShowHandout handout={handout} onClick={this.hideHandout.bind(this)} size={size} character={character}/>:null}
        </div>;
    }

    showEditObject(c){
        this.setState({showEditObject:true, editObject:c});
    }

    showMapSettings(showMapSettings) {
        this.setState({showMapSettings});
    }

    handleEditObject(ao) {
        if (ao && this.combatants) {
            this.combatants.setCombatant(ao);
        }
        this.setState({showEditObject:false});
    }


    getTopToolbar() {
        if (this.map) {
            const {variant} = this.props;
            if (!["edit"].includes(variant)) {
                return;
            }
            const mode=this.map.mode;

            const tools=[];

            if (variant == "gm") {
                tools.push({
                    tool:"fas fa-share-square",
                    toolTip:"Project",
                    onClick:null,
                    selected:false
                });
            }
            
            tools.push({
                tool:"fas fa-arrows-alt",
                toolTip:"Move",
                onClick:this.setMode.bind(this,"move"),
                selected:(mode=="move")
            });
            tools.push({
                tool:"far fa-object-group",
                toolTip:"Select",
                onClick:this.setMode.bind(this,"select"),
                selected:(mode=="select")
            });
            tools.push({
                tool:this.map.showGrid?"fas fa-th-large":"fas fa-square-full",
                toolTip:this.map.showGrid?"Hide Grid":"Show Grid",
                onClick:this.toggleGrid.bind(this)
            });
            tools.push({
                tool:"fas fa-search-plus",
                toolTip:"Zoom",
                onClick:this.showZoom.bind(this,true)
            });
            if (variant != "edit") {
                tools.push({
                    tool:"fas fa-images",
                    toolTip:"Pick Map",
                    onClick:null
                });
            }

            if (variant == "gm") {
                tools.push({
                    tool:"fas fa-scroll",
                    toolTip:"Pick Handout",
                    onClick:null
                });
                tools.push({
                    tool:"fas fa-magnet",
                    toolTip:"Center Player View",
                    onClick:null
                });
            }

            tools.push({
                spacer:true
            });
            tools.push({
                tool:"fas fa-eye",
                toolTip:"Fog Tools",
                onClick:null,
                selected:false
            });
            tools.push({
                tool:"fas fa-object-ungroup",
                toolTip:"Build Regions",
                onClick:null,
                selected:false
            });
            tools.push({
                tool:"fas fa-draw-polygon",
                toolTip:"Polygon",
                onClick:null,
                selected:false
            });
            tools.push({
                tool:"fas fa-vector-square",
                toolTip:"Rectangle",
                onClick:null,
                selected:false
            });
            tools.push({
                tool:"fas fa-brush",
                toolTip:"Brush",
                onClick:null,
                selected:false
            });
            tools.push({
                tool:"fas fa-pen",
                toolTip:"Draw",
                onClick:null,
                selected:false
            });
            if (this.map.mapInfo) {
                tools.push({
                    tool:"fas fa-cog",
                    toolTip:"Settings",
                    onClick:this.showMapSettings.bind(this,true),
                });
            }

            if (tools.length) {
                const list = [];

                for (let i in tools) {
                    const t = tools[i];
                    if (t.spacer) {
                        list.push(<span key={i} className="flex-auto"/>)
                    } else {
                        list.push(<span onClick={t.onClick||null} className={"dib br2 pv--2 hoverhighlight tc"+(i>0?" ml--3":"")+(t.selected?" bg-gray-40":"")} style={{width:toolWidth}} key={i}>
                            <Tooltip title={t.toolTip}><span className={t.tool}/></Tooltip>
                        </span>);
                    }
                }

                //console.log("draw tool", top,left,pos, width, height, toolbarHalfWidth, toolbarHeight);
                return <div className="maptool flex f2 ph1 pv--2 cursor-default" style={{height:topToolbarHeight}}>
                    {list}
                </div>
            }
        }
    }

    toggleGrid() {
        this.map.setShowGrid(!this.map.showGrid);
    }

    getSelectedToolbar() {
        if (this.map) {
            const {size, variant} = this.props;
            const selectionType = this.map.getSelectionType();
            let tools=[], forceTop;

            switch (variant) {
                case "player":
                    forceTop = this.getPlayerTools(tools, selectionType);
                    break;
            }

            if (tools.length) {
                let {width,height} = size;
                let top=5, left=5;
                const toolbarHalfWidth = (((tools.length)*toolWidth+3)+10)/2;
                const toolbarHeight = 40;
                const pos = this.map.getSelectedPosition();
                if (!forceTop && pos) {
                    left = Math.min(Math.max(pos.x-toolbarHalfWidth, 5), width-toolbarHalfWidth-10);
                    top = Math.min(Math.max(pos.y, 5), height-toolbarHeight);
                }
                const list = [];

                for (let i in tools) {
                    const t = tools[i];
                    list.push(<span onClick={t.onClick||null} className={"dib br2 pv--2 hoverhighlight tc"+(i>0?" ml--3":"")+(t.selected?" bg-gray-40":"")} style={{width:toolWidth}} key={i}>
                        <Tooltip title={t.toolTip}><span className={t.tool}/></Tooltip>
                    </span>);
                }

                //console.log("draw tool", top,left,pos, width, height, toolbarHalfWidth, toolbarHeight);
                return <div className="maptool shadow-3 f2 ph1 pv--2 ba br4 cursor-default" style={{position:"absolute", top, left}}>
                    {list}
                </div>
            }
        }
    }

    getPlayerTools(tools, selectionType) {
        const mode = this.map.mode;
        switch (selectionType) {
            case "combatants":
                if (this.combatants) {
                    const selected = this.combatants.selected;
                    const ids = Object.keys(selected);
                    if (ids.length != 1) {
                        return;
                    }

                    const cInfo = this.combatants.getCombatant(ids[0]);
                    if (cInfo) {
                        const {ctype} = cInfo;
                        if (ctype == "object") {
                            tools.push({
                                tool:"fas fa-edit",
                                toolTip:"Edit",
                                onClick:this.showEditObject.bind(this, cInfo)
                            })
                        }
                        if (["object", "cmonster"].includes(ctype)) {
                            tools.push({
                                tool:"fas fa-trash",
                                toolTip:"Delete",
                                onClick:this.combatants.deleteCombatant.bind(this.combatants, cInfo.id)
                            });
                        }
                    }
                }
                return false;
            case "stage":
                tools.push({
                    tool:"fas fa-arrows-alt",
                    toolTip:"Move",
                    onClick:this.setMode.bind(this,"move"),
                    selected:(mode=="move")
                });
                tools.push({
                    tool:"far fa-object-group",
                    toolTip:"Select",
                    onClick:this.setMode.bind(this,"select"),
                    selected:(mode=="select")
                });
                if (this.map.mapPos) {
                    tools.push({
                        tool:"fas fa-search-plus",
                        toolTip:"Zoom",
                        onClick:this.showZoom.bind(this,true)
                    });
                }
                tools.push({
                    tool:"fas fa-times",
                    toolTip:"Hide",
                    onClick:this.clearSelection.bind(this)
                });
                return true;
        }
        return false;
    }

    setMode(mode) {
        this.map.setMode(mode);
    }

    clearSelection() {
        this.map.clearSelection();
    }

    hideHandout() {
        this.setState({handout:null});
    }

    saveRef(ref) {
        if (ref) {
            this.contentRef = ref;
            if (this.map) {
                this.map.container = ref;
            }
        }
    }

    tapCombatant(id, index, event) {
        const {onTapCombatant} = this.props;
        if (onTapCombatant) {
            onTapCombatant(id, index, event);
        }
    }

    selectChange() {
        this.setState({select:campaign.newUid()});
    }

    showZoom(showZoom,evt) {
        this.setState({showZoom, anchorEl:evt&&evt.target});
    }

    getZoom(scale, onScale) {
        const {showZoom,anchorEl} = this.state;
        if (!showZoom) {
            return null;
        }

        const {diameter} = this.map.mapPos;
        const mapDimensions = this.map.mapDimensions;
        const maxDim = Math.max(mapDimensions.width, mapDimensions.height)
        const logValue = Math.log(maxDim/diameter)/Math.log(1.1);

        return <Popover 
            open 
            anchorOrigin={{vertical: 'bottom',horizontal: 'center',}}
            transformOrigin={{vertical: 'top',horizontal: 'center',}} 
            anchorEl={anchorEl} 
            onClose={this.showZoom.bind(this,false)}
        >
            <Paper className="darkT darkBackground">
                <div className="tc titlecolor hoverhighlight" onClick={this.setZoom.bind(this,null, logValue+1)}><span className="far fa-plus-square f2 pa1"/></div>
                <div className="h6 flex mv1">
                    <div className="flex-auto"/>
                    <Slider classes={{root:"ph2"}} orientation="vertical" value={logValue} min={-10} max={30} step={1} onChange={this.setZoom.bind(this)}/>
                    <div className="flex-auto"/>
                </div>
                <div className="tc titlecolor hoverhighlight" onClick={this.setZoom.bind(this,null, logValue-1)}><span className="far fa-minus-square f2 pa1"/></div>
                <div className="tc titlecolor pa1 hoverhighlight f3" onClick={this.setZoom.bind(this,null, 1)}>all</div>
            </Paper>
        </Popover>;
    }

    setZoom(e,logScale) {
        const mapPos = Object.assign({}, this.map.mapPos);

        const {diameter} = mapPos;
        const mapDimensions = this.map.mapDimensions;
        const maxDim = Math.max(mapDimensions.width, mapDimensions.height)
        const mult = Math.pow(1.1, logScale);
        mapPos.diameter = maxDim/mult;
        if (logScale == 1) {
            mapPos.x = mapDimensions.width/2;
            mapPos.y = mapDimensions.height/2;
        }
        this.map.setMap(this.map.mapInfo.name, mapPos,true);
    }

}

class Map {
    constructor(options) {

        this.events = new EventEmitter();

        const stage = new Konva.Stage({
            container: options.container,
            width:options.width||1,
            height:options.height||1,
            name:"stage"
        });
        stage.stage=true;

        this.width = options.width||1;
        this.height = options.height||1;

        this.showName = options.showName;
        this.playerView = options.playerView;
        this.campaignMode = options.campaignMode;
        this.variant = options.variant;
        this.mode = options.mode;

        this.backgroundLayer = new Konva.Layer();
        stage.add(this.backgroundLayer);

        this.mapGroup = new Konva.Group();
        this.backgroundLayer.add(this.mapGroup);
        this.gridGroup = new Konva.Group();
        this.backgroundLayer.add(this.gridGroup);
        this.pinGroup = new Konva.Group();
        this.backgroundLayer.add(this.pinGroup);

        this.drawLayer = new Konva.Layer();
        stage.add(this.drawLayer);

        this.tokenLayer = new Konva.Layer();
        stage.add(this.tokenLayer);

        if (options.showCover) {
            this.showCover = true;
            this.coverLayer = new Konva.Layer({opacity:this.playerView?1:0.5, listening:false});
            stage.add(this.coverLayer);

            this.coverGroup = new Konva.Group();
            this.coverLayer.add(this.coverGroup);
        }

        this.topLayer = new Konva.Layer({listening:false});
        stage.add(this.topLayer);
        if (!["popout","inline"].includes(this.variant)) {
            stage.on("mousedown touchstart", this.onMouseDown.bind(this));
            stage.on("mouseup touchend", this.onMouseUp.bind(this));
            stage.on("mousemove touchmove", this.onMouseMove.bind(this));
            stage.on("wheel", this.onWheel.bind(this))

            stage.on("dragmove", this.onDragMove.bind(this));
            stage.on("mouseenter", this.onMouseEnter.bind(this));
            stage.on("mouseleave", this.onMouseLeave.bind(this));
        } else {
            stage.listening(false);
        }

        this.pingGroup = new Konva.Group();
        this.topLayer.add(this.pingGroup);

        this.stage = stage;

        this.onMapUpdateFn = this.onMapUpdate.bind(this);
        globalDataListener.onChangeCampaignContent(this.onMapUpdateFn, "maps");
        globalDataListener.onChangeCampaignContent(this.onMapUpdateFn, "mapextra");

        this.onArtUpdateFn = this.onArtUpdate.bind(this);
        globalDataListener.onChangeCampaignContent(this.onArtUpdateFn, "art");

        this.onPinsChangeFn = this.updatePins.bind(this);
        globalDataListener.onChangeCampaignContent(this.onPinsChangeFn, "pins");

        if (this.campaignMode) {
            this.onAdventureUpdateFn = this.onAdventureUpdate.bind(this);
            globalDataListener.onChangeCampaignContent(this.onAdventureUpdateFn, "adventureview");
            globalDataListener.onChangeCampaignContent(this.onAdventureUpdateFn, "adventure");
        }
        this.pingProgress = {};
        this.loadInitialState();
        //console.log("map", this)
    }

    destroy() {
        if (this.loadingAnim) {
            this.loadingAnim.stop();
        }
        this.stage.destroy();
        this.backgroundLayer = null;
        this.tokenLayer = null;
        this.topLayer = null;
        this.stage = null;

        this.combatants=null;
        this.combatantMapDetails=null;

        globalDataListener.removeCampaignContentListener(this.onMapUpdateFn, "maps");
        globalDataListener.removeCampaignContentListener(this.onMapUpdateFn, "mapextra");

        globalDataListener.removeCampaignContentListener(this.onArtUpdateFn, "art");

        globalDataListener.removeCampaignContentListener(this.onPinsChangeFn, "pins");

        if (this.campaignMode) {
            globalDataListener.removeCampaignContentListener(this.onAdventureUpdateFn, "adventureview");
            globalDataListener.removeCampaignContentListener(this.onAdventureUpdateFn, "adventure");
        }

        if (this.pingtimer) {
            clearInterval(this.pingtimer);
            this.pingtimer=null;
        }
        this.cancelPingCheck();

        this.events.removeAllListeners();
        this.events=null;
    }

    freezeUpdates() {
        if (!this.pending) {
            this.pending={x:1};
        }
    }

    unfreezeUpdates() {
        if (this.pending) {
            const pending = this.pending;
            this.pending = null;
            if (pending.mapUpdate) {
                this.onMapUpdate();
            }
            if (pending.artUpdate) {
                this.onArtUpdate();
            }
            if (pending.pinUpdate) {
                this.updatePins();
            }
            if (pending.adventureUpdate) {
                this.onAdventureUpdate();
            }
        }
    }


    loadInitialState() {
        if (this.campaignMode) {
            const av = campaign.getAdventureView();
            console.log("av", av)
            if (av) {
                this.setMap(av.imageName,av.mapPos);
                this.cversion = av.cversion;
                this.setShowGrid(av.grid);
            }
        }
    }

    setMode(mode) {
        this.mode = mode;
        this.updateSelection();
    }

    setEncounterCombatants(ec) {
        this.combatants = ec;
        this.updateCombatants();
        ec.eventSync.on("change", this.updateCombatants.bind(this));
    }

    onAdventureUpdate() {
        this.startPingUpdate();
        if (this.pending) {
            this.pending.adventureUpdate = true;
            return;
        }
        const av = campaign.getAdventureView();
        //console.log("adventure change", av, this.lastAv);
        this.lastAv = av;
        if (this.playerView) {
            if ((av.imageName != this.mapName) || (this.cversion != av.cversion) || (this.variant == "popout")) {
                this.setMap(av.imageName,av.mapPos);
                this.cversion = av.cversion;
            }
            this.setShowGrid(av.grid);
        }
    }

    setMap(name, mapPos, force) {
        //console.log("set map",name, mapPos);
        if (name && (force || (name != this.mapInfo?.name))) {
            let mapInfo = campaign.getMapInfo(name);
            //console.log("get mapInfo", mapInfo);
            if (mapInfo) {
                const art = campaign.getArtInfo(mapInfo.art)||mapInfo;
                //console.log("get art", art);
                let pixels = 70;
                let gridSize = 5;
                let pixelMult=1;
                
                if (art.imgWidth && art.imgHeight) {
                    if (mapInfo.pixelsPerGrid)
                        pixels = Number(mapInfo.pixelsPerGrid);

                    if (mapInfo.gridSize) {
                        gridSize = Number(mapInfo.gridSize);
                    }

                    if (mapInfo.units == "miles") {
                        gridSize = gridSize*5280;
                    }

                    if (art.originalWidth && (art.originalWidth != art.imgWidth)) {
                        pixelMult = art.imgWidth/art.originalWidth;
                    }
                
                    pixels = pixels*pixelMult;
                    const mult = gridSize/pixels;

                    this.pixelsPerFoot = 1/mult;
                    this.mapDimensions = {width:art.imgWidth*mult, height:art.imgHeight*mult};
                    this.gridSizeInFeet = gridSize;
                    this.gridxShift = (mapInfo.gridxShift||0)*mult*pixelMult;
                    this.gridyShift = (mapInfo.gridyShift||0)*mult*pixelMult;
                    this.mapInfo=mapInfo;
                    this.mapextra=campaign.isCampaignGame()?campaign.getMapExtraInfo(name):null;

                    let useArt=art;
                    let altImage = this.mapextra?.altImage;
                    if (altImage && (mapInfo.artList||[]).includes(altImage)) {
                        useArt = campaign.getArtInfo(altImage) || art;
                    }

                    if (useArt.url != this.mapUrl) {
                        this.mapUrl=null;
                        this.loadMap(useArt.url);
                    }
                    this.updateCover();
                    this.updateDrawing();
                    this.updateCombatants();
                    this.updatePins();
                } else {
                    this.resetMap();
                }
            } else {
                this.resetMap();
            }
        }
        if (mapPos) {
            this.mapPos=mapPos;
        }
        this.updateStageZoom();
    }

    onMapUpdate() {
        if (this.pending) {
            this.pending.mapUpdate=true;
            return;
        }
        
        if (this.mapInfo?.name) {
            let mapInfo = campaign.getMapInfo(this.mapInfo.name);
            if (mapInfo) {
                const mapextra=campaign.isCampaignGame()?campaign.getMapExtraInfo(name):null;
                if (!areSameDeep(mapInfo, this.mapInfo) || !areSameDeep(mapextra, this.mapextra)) {
                    //console.log("map updated", areSameDeep(mapInfo, this.mapInfo), !areSameDeep(mapextra, this.mapextra));
                    this.setMap(this.mapInfo.name, null, true);
                }
            } else {
                this.resetMap();
            }
        }
    }

    async loadMap(url) {
        const name = this.mapName;
        this.incrementLoading();
        let {width, height} = this.mapDimensions||{};
        this.mapGroup.destroyChildren();
        try {
            this.loadError = null;
            this.mapUrl = url;
            //await sleep(3000);
            const imageObj = await imageCache.loadImage(url);
            if (name == this.mapName) {
                let image = new Konva.Image({image: imageObj, x:0,y:0, width, height});
                this.mapGroup.add(image);
            }
        } catch (err) {
            console.log("error loading map", err);
            let fillScale = width/100;
            this.loadError = err;
            this.mapUrl = null;

            let image = new Konva.Rect({x:0, y:0, width, height, fillPatternImage:this.getErrorFill(), fillPatternScale:{x:fillScale, y:fillScale}, fillPatternRepeat:"repeat"});
            this.mapGroup.add(image);
        }
        this.decrementLoading();
    }

    onArtUpdate() {
        if (this.pending) {
            this.pending.artUpdate = true;
            return;
        }
        if (this.mapInfo?.name) {
            let mapInfo = campaign.getMapInfo(this.mapInfo.name);
            if (mapInfo) {
                this.setMap(this.mapInfo.name, null, true);
            } else {
                this.resetMap();
            }
        }
    }

    destroyName(name) {
        if (this.stage) {
            const node = this.stage.findOne(name);
            node && node.destroy();
        }
    }

    get mapName() {
        return this.mapInfo?.name;
    }

    setMapPos(mapPos) {
        if (mapPos) {
            if (mapPos.mapName) {
                this.setMap(mapPos.mapName,mapPos)
            } else {
                this.mapPos = mapPos;
                //console.log("set map pos", mapPos);
                this.updateStageZoom();
            }
        }
    }

    updateStageZoom() {
        if (this.mapInfo && this.stage) {
            if (!this.mapPos || isNaN(this.mapPos.diameter) || isNaN(this.mapPos.x) || isNaN(this.mapPos.y)) {
                this.mapPos = this.showAllMap();
            }
            let {diameter, x, y} = this.mapPos||{};
            let viewWidth = this.width, viewHeight = this.height;
            let scale = Math.min(viewWidth, viewHeight)/diameter;
            let newX = viewWidth/2-x*scale, newY=viewHeight/2-y*scale;

            this.stage.scale({x:scale, y:scale});

            this.stage.position({x:newX, y:newY});
            this.scale = scale;

            let viewPort = {left:-newX/scale, top:-newY/scale};
            viewPort.right=viewPort.left+viewWidth/scale;
            viewPort.bottom=viewPort.top+viewHeight/scale;
            this.viewPort = viewPort;

            let loading = this.stage.findOne(".loading");
            if (loading) {
                loading.scale({x:1/scale, y:1/scale});
                loading.x(x+((viewWidth/2)-25)/scale);
                loading.y(y-((viewHeight/2)-25)/scale);
                //console.log("scale", viewWidth, viewHeight, viewWidth/scale, viewHeight/scale,this.loading.x(),this.loading.y(), this.stage.x(),this.stage.y(), this.mapPos);
            }
            this.genGridLines();
        }
    }

    showAllMap() {
        let {width=10,height=10} = this.mapDimensions||{};
        let viewWidth = this.width, viewHeight = this.height;
        let mapPos={x:width/2, y:height/2, mapName:this.mapInfo.name};

        if (this.variant == "inline") {
            if (viewWidth > viewHeight) {
                mapPos.diameter = Math.min(width,height);
            } else {
                mapPos.diameter = Math.max(width,height);
            }
        } else if (width/height > viewWidth/viewHeight) {
            // too wide relative to view
            mapPos.diameter=width;
        } else {
            // too tall to view
            mapPos.diameter = height;
        }
        return mapPos;
    }

    resetMap(){
        this.mapInfo=null;
        this.mapextra=null;
        this.mapUrl=null;
        if (this.combatants) {
            this.combatants.destroy();
            this.combatants=null;
        }
        this.combatantMapDetails=null;
        this.pins=null;
        this.mapGroup && this.mapGroup.destroyChildren();
        this.gridGroup && this.gridGroup.destroyChildren();
        this.pinGroup && this.pinGroup.destroyChildren();

        this.drawLayer && this.drawLayer.destroyChildren();
        this.drawObjs && this.drawObjs.destroyChildren();
        this.tokenLayer && this.tokenLayer.destroyChildren();
        if (this.coverLayer) {
            this.coverObjs=null;
            this.coverGroup.destroyChildren();
        }
        this.pingProgress = {};
        this.topLayer && this.topLayer.destroyChildren();
    }

    set container(container) {
        this.stage.container(container);
    }

    setDimensions(width, height) {
        this.height = height||10;
        this.width = width||10;
        this.stage.width(width);
        this.stage.height(height);
        this.updateStageZoom()
    }

    getCombatantDraggable(c) {
        switch (this.variant) {
            case "player":
                if (c.canMove) {
                    return true;
                }
                break;
        }
        return false;
    }

    getStageDraggable(e) {
        switch (this.variant) {
            case "player":
                break;
        }

        if ((e.evt.buttons==2||e.evt.ctrlKey)) {
            return true;
        }
        if (this.mode == "select") {
            return false;
        }
        return true;
    }

    getPinDraggable(p) {
        switch (this.variant) {
            case "gm":
            case "edit":
            case "encounter":
                return true;
                break;
        }

    }

    updateCombatants() {
        if (this.combatants && this.mapInfo) {
            const mc =  this.combatants.getMapDetails((this.mapInfo.name||"F").toLowerCase());
            if (!areSameDeep(this.combatantMapDetails, mc)) {
                if (mc && mc.length) {
                    removeFromGroup(this.tokenLayer.getChildren(), mc)
                    //console.log("update combatants", mc);
                    for (let i in mc) {
                        const c = mc[i]
                        const p = this.tokenLayer.findOne("."+c.id);
                        if (!p || !areSameDeepIgnore(c, p.cInfo,["tokenX","tokenY"])) {
                            let group;
                            if (p) {
                                p.destroyChildren();
                                p.position({x:c.tokenX, y:c.tokenY});
                                group = p;
                            } else {
                                group = new Konva.Group({
                                    name:c.id, 
                                    x:c.tokenX, 
                                    y:c.tokenY
                                });
                                this.tokenLayer.add(group);
                            }

                            group.cInfo = c;
                            this.genCombatant(group, c);
                            group.zIndex(i);
                        } else {
                            if (p.cInfo.tokenX != c.tokenX) {
                                p.x(c.tokenX);
                            }
                            if (p.cInfo.tokenY != c.tokenY) {
                                p.y(c.tokenY);
                            }
                            if (p.zIndex() != i) {
                                p.zIndex(i);
                            }
                            p.cInfo = c;
                        }
                    }
                } else {
                    this.tokenLayer.destroyChildren();
                }
                this.combatantMapDetails = mc;
            }
        } else {
            this.tokenLayer.destroyChildren();
        }
        this.updateSelection();
    }

    updatePins() {
        if (this.pending) {
            this.pending.pinUpdate = true;
            return;
        }
        if (this.mapInfo) {
            const pins =  this.getMapPins(this.mapInfo.name);
            if (!areSameDeep(this.pins, pins)) {
                if (pins.length) {
                    const children = this.pinGroup.getChildren();
                    removeFromGroup(children, pins);
                    //console.log("update pins", pins);
                    for (let i in pins) {
                        const pin = pins[i]
                        const p = this.pinGroup.findOne("."+pin.name);
                        if (!p || !areSameDeep(pin, p.pin)) {
                            if (p) {
                                p.destroy();
                            };
                            const group = new Konva.Group({name:pin.name, x:pin.mapPos.x, y:pin.mapPos.y});
                            group.pin = pin;
                            this.genPin(group,pin)
                            this.pinGroup.add(group);
                            group.zIndex(i);
                        } else {
                            if (p.zIndex() != i) {
                                p.zIndex(i);
                            }
                        }
                    }
                } else {
                    this.pinGroup.destroyChildren();
                }
                this.pins = pins;
            }
        } else {
            this.pinGroup.destroyChildren();
        }
    }

    genPin(group,pin) {
        const ignoreHidden = ["inline"].includes(this.variant);
        let pinScale;
        if (isNaN(pin.scale)) {
            const autoBase=0.3*(this.gridSizeInFeet||5)/12;
            switch (pin.scale) {
                case "axl":
                    pinScale= autoBase*2;
                    break
                case "al":
                    pinScale = autoBase*1.5;
                    break
                case "as":
                    pinScale= autoBase*0.75;
                    break
                case "axs":
                    pinScale= autoBase*0.5;
                    break;
                case "am":
                default:
                    pinScale= autoBase;
            }
        } else {
            const fixBase=100/72/this.pixelsPerFoot;
            pinScale = Number(pin.scale)/18*fixBase;
        }
        group.scale({x:pinScale, y:pinScale});
        const name = pin.displayName;
        let selX=-9, selY=-9, selWidth=18, selHeight=0;
        if (name) {
            const text = new Konva.Text({
                text:name,
                fontSize:18,
                fontFamily:"Convergence, sans-serif",
                y:-28,
                fill:(pin.showPlayers||ignoreHidden)?"white":"mediumspringgreen",
                opacity:0.87
            });
            text.x(-text.width()/2);
            const rect = new Konva.Rect({
                x:-(text.width()+4)/2,
                y:-31,
                width:text.width()+4,
                height:22,
                fill:"#58170D",
                opacity:0.5,
                cornerRadius:4
            });
            group.add(rect);
            selX = rect.x()-1;
            selY = rect.y()-1;
            selWidth = rect.width()+2;
            selHeight = rect.height()+2;
            group.add(text);
        }

        let marker, numPoints=5;
        switch (pin.marker) {
            default:
            case "circle":
                marker = new Konva.Circle({
                    radius:6,
                    stroke:"black",
                    fill:"white",
                    opacity:0.65,
                    strokeScaleEnabled:true,
                    strokeWidth:1.5,
                });
                break;
            case "triangle":
            case "diamond":
                numPoints=4;
            case "star":
                if (pin.marker=="triangle") {
                    numPoints=3;
                }
                marker = new Konva.Star({
                    numPoints,
                    outerRadius:8,
                    innerRadius:4,
                    stroke:"black",
                    fill:"white",
                    opacity:0.65,
                    strokeScaleEnabled:true,
                    strokeWidth:1.5,
                });
                break;
            case "square":
                marker = new Konva.Rect({
                    x:-6,
                    y:-6,
                    width:12,
                    height:12,
                    stroke:"black",
                    fill:"white",
                    opacity:0.65,
                    strokeScaleEnabled:true,
                    strokeWidth:1.5,
                });
                break;
            case "none":
                return;
        }
        if (marker) {
            selHeight+=16;
            group.add(marker);
        }
        if (pin.selected) {
            group.add(new Konva.Rect({
                x:selX,
                y:selY,
                width:selWidth,
                height:selHeight,
                stroke:selectHighlight,
                strokeScaleEnabled:false,
                strokeWidth:2.5,
            }), new Konva.Rect({
                x:selX,
                y:selY,
                width:selWidth,
                height:selHeight,
                stroke:"cyan",
                strokeScaleEnabled:false,
                strokeWidth:1.5,
            }));
        }
    }

    getMapPins(name) {
        const pinList = [];
        if (name) {
            name=name.toLowerCase();
            const pins = campaign.getPins();
            for (let i in pins) {
                let p = pins[i];

                if ((p.mapPos.mapName||"").toLowerCase()==name) {
                    if ((p.showPlayers || !this.playerView)) {
                        if (p.name == this.selectedPin) {
                            p = Object.assign({}, p);
                            p.selected = true;
                        }
                        pinList.push(p);
                    }
                }
            }
        }
        return pinList;
    }

    changePins(pupdates) {
        for (let pu of (pupdates||[])) {
            const op = campaign.getPinInfo(pu.name);
            if (op) {
                const p = Object.assign({}, op);
                const mapPos = Object.assign({}, p.mapPos);
                mapPos.x=pu.x;
                mapPos.y=pu.y
                p.mapPos = mapPos;
                mapPos.diameter = this.mapPos.diameter;
                campaign.updateCampaignContent("pins", p);
            } else {
                console.log("could not find pin", pu)
            }
        }
    }

    setShowNames(show) {
        if (show != this.showNames) {
            this.showNames = show;
            this.updateCombatants();
        }
    }

    redrawCombatant(id) {
        const group = this.tokenLayer && this.tokenLayer.findOne("."+id);
        if (!group) {
            //console.log("could not find group to update",id);
            return;
        }
        const c = (this.combatantMapDetails||[]).find(function(d) {return id==d.id});
        if (c) {
            //console.log("redraw", id);
            group.destroyChildren();
            this.genCombatant(group,c);
        }
    }

    genCombatant(group, c) {
        let rectColors=[];
        if (c.currentTurn) {
            rectColors.push("yellow");
        }
        if (c.select) {
            rectColors.push("cyan");
        }

        if (c.hover) {
            rectColors.push("red");
        }

        group.rotation(c.rotation);
        switch (c.tokenType) {
            case "nametoken":
                return this.genNameToken(group,c,rectColors);
            case "rectangle":
            case "cube":
            case "line":
            case "cone":
                return this.genLineToken(group,c,rectColors);
            case "circle":
            case "cylinder":
            case "sphere":
                return this.genCircleToken(group,c,rectColors);
            case "image":
            case "imagetoken":
                return this.genImageToken(group,c,rectColors);
        }
        
    }

    genImageToken(group,c,rectColors) {
        const delayLoad = this.redrawCombatant.bind(this,c.id);
        const image = imageCache.getImage(c.tokenImageURL,delayLoad, null, testCallback);
        if (image) {
            const opacity= (c.hidden?0.7:1)*(c.opacity||1);
            let width=Number(c.width), height=Number(c.height);
            group.add(new Konva.Image({
                image,
                x:-width/2,
                y:-height/2,
                opacity,
                width,
                height
            }));

            this.genRects(group, rectColors, width+doubleSelWidth, height+doubleSelWidth, width/2+selWidth, height/2+selWidth, selWidth);
            this.genRotateHandle(group,c, height, width,height/2);
        }
    }

    genCircleToken(group,c,rectColors) {
        const opacity= c.hidden?0.7:1;
        const radius = (c.tokenType=="circle")?((c.diameter||5)/2):(c.width||2.5);
        group.add(new Konva.Circle({
            x:0,
            y:0,
            radius,
            fill:c.fill||"white",
            opacity:0.6*opacity,
        }));
        const width=radius*2+doubleSelWidth,
            offset = width/2;
        this.genRects(group, rectColors, width, width, offset, offset, selWidth);
    }

    genLineToken(group,c,rectColors) {
        const opacity= c.hidden?0.7:1;
        let height, width, offsetX, offsetY;
        let points;

        switch (c.tokenType) {
            case "rectangle":
                height = Number(c.height)||5;
                width = Number(c.width)||5;
                offsetX = width/2;
                offsetY = height/2;
                break;
            case "cube":
                height = width = Number(c.width)||5;
                offsetX = width/2;
                offsetY = height;
                break;
            case "line":
                height = Number(c.width)||5;
                width = 5;
                offsetX = width/2;
                offsetY = height;
                break;
            case "cone":
                height = width = Number(c.width)||5;
                offsetX = width/2;
                offsetY = height;
                points = [width/2,height,0,0,width,0];
                break;
        }
        if (!points) {
            points = [0,0,0,height,width,height,width,0];
        }
        group.add(new Konva.Line({
            points,
            closed:true,
            fill:c.fill||"white",
            x:0, y:0,
            offsetX,offsetY,
            opacity:0.6*opacity,
            cornerRadius:0.25
        }));
        this.genRects(group, rectColors, width+doubleSelWidth, height+doubleSelWidth, offsetX+selWidth, offsetY+selWidth, selWidth);

        this.genRotateHandle(group,c, height, width,offsetY);
    }

    genRects(group, rectColors, width, height, offsetX, offsetY, strokeWidth) {
        const len = (rectColors || []).length;
        if (!len) {
            return;
        }

        group.add(new Konva.Rect({
            width:width+strokeWidth*(len-1)*1.5,
            height:height+strokeWidth*(len-1)*1.5,
            offsetX:offsetX+strokeWidth*(len-1)*0.75,
            offsetY:offsetY+strokeWidth*(len-1)*0.75,
            stroke:selectHighlight,
            strokeWidth:strokeWidth*len*1.5+strokeWidth*0.5,
            shadowBlur:strokeWidth*2
        }));

        for (let rectColor of rectColors) {
            group.add(new Konva.Rect({
                width,
                height,
                offsetX,
                offsetY,
                stroke:rectColor,
                strokeWidth
            }));
            width+=strokeWidth*3;
            height+=strokeWidth*3;
            offsetX+=strokeWidth*1.5;
            offsetY+=strokeWidth*1.5;
        }

    }

    genRotateHandle(group, c, height, width,offsetY) {
        if (c.select && this.getCombatantDraggable(c)) {
            const radius = Math.max(0.8, Math.min(height,width)/30);
            const innerRadius = radius*0.5;
            const strokeWidth = radius*0.2;
            const arrowSize = radius*0.5;
            const handleOffset = (offsetY||0)+(radius*1.5);

            const handle = new Konva.Circle({
                x:0,y:0,
                offsetY:handleOffset,
                radius,
                fill:toolBackground,
                opacity:0.8,
                name:"draghandle",
            });
            handle.handle = true;

            group.add(handle,new Konva.Arc({
                x:0,y:0,
                offsetY:handleOffset,
                innerRadius:innerRadius,
                outerRadius:innerRadius,
                strokeWidth,
                stroke:toolStroke,
                angle:300,
                strokeCap:"round",
                listening:false
            }),new Konva.Arrow({
                x:0,
                y:-innerRadius,
                offsetY:handleOffset,
                points:[0,0,arrowSize,0],
                fill:toolStroke,
                pointerLength:arrowSize,
                pointerWidth:arrowSize,
                listening:false
            }));
        }
    }

    genNameToken(group, c,rectColors) {
        if (!c.tokenImageURL) {
            return;
        }
        let name = maxLen(c.displayName || "", 30);
        let opacity;

        const id=c.number;
        if (c.hideName || ((this.showNames=="hide") && (c.group=="pc"))) {
            name = (id||"").toString();
        } else if ((this.showNames=="abbrev") && (c.group=="pc")) {
            const s = name.split(" ");
            let an = "";
            for (let i in s) {
                an += s[i].substr(0,1);
            }
            name=an;
            if (id) {
                name = "("+id+") "+name;
            }
        } else {
            if (id) {
                name = "("+id+") "+name;
            }
        }

        const s = (sizeScaleMap[c.tokenSize]||1)*gridBaseSize;
        let health;
        let showCondition = showConditionIndicator(c.conditions);
        let healthColor;
        let showDead;
        let almostDead;

        switch (c.state) {
            case "inactive":
                opacity=0.85;
                break;
            case "active":
            default:
                if (c.type && (c.hp==0)) {
                    opacity = 0.65;
                    showDead=true;
                } else {
                    if (c.hidden) {
                        opacity=0.7;
                    } else {
                        opacity=1;
                    }
                    health = (c.hp||0)/(c.hpMax||1);
                    if (health) {
                        if (health > 0.75) {
                            //healthColor="green";
                        } else if (health > 0.5) {
                            //healthColor="gold";
                        } else if (health > 0.25) {
                            healthColor="orange";
                        } else {
                            healthColor="red";
                            // don't let bar get too small
                        }
                    }
                }
                break;
        }

        if (c.hp==0) {
            if ((c.ctype=="pc") && !c.showDead) {
                almostDead=true;
            } else {
                showDead = true;
            }
        }

        group.scale({x:5/gridBaseSize,y:5/gridBaseSize});
        group.x(c.tokenX);
        group.y(c.tokenY);
        group.opacity(opacity);
        
        const delayLoad = this.redrawCombatant.bind(this,c.id);
        const tokenImage = imageCache.getImage(c.tokenImageURL,delayLoad, null, testCallback);
        if (tokenImage) {
            group.add(new Konva.Image({
                image:tokenImage,
                width:s,
                height:s,
                x:-s/2,
                y:-s/2
            }));
        }
        let overlay;
        if (showDead) {
            overlay = imageCache.getImage(skullUrl,delayLoad, null, testCallback);
        } else if (almostDead) {
            overlay = imageCache.getImage(heartbeatUrl,delayLoad, null, testCallback);
        }

        if (overlay) {
            group.add(new Konva.Image({
                image:overlay,
                width:s,
                height:s,
                x:-s/2,
                y:-s/2
            }));
        }

        if (healthColor) {
            group.add(new Konva.Circle({
                radius:s/10,
                offsetX:s*0.39,
                offsetY:s*0.39,
                fill:healthColor,
                opacity:0,
                shadowBlur:2,
                shadowColor:"black"    
            }),new Konva.Circle({
                radius:s/2+1,
                stroke:healthColor,
                opacity:0.7,
                shadowBlur:2,
                strokeWidth:4
            }));
        }

        if (rectColors) {
            const width=s+8,
            height=s+18,
            offset = width/2;
            this.genRects(group, rectColors, width, height, offset, offset, 2);
        }

        if (showCondition) {
            group.add(addCondition(new Konva.Group({
                scaleX:s*0.0028,
                scaleY:s*0.0028,
                x:s*0.225,
                y:-s*0.5,
                opacity:0.8,
                shadowBlur:2,
                shadowColor:"black"
            })));
        }

        if (name) {
            const text = new Konva.Text({
                text:name,
                fontSize:18,
                fontFamily:"Convergence, sans-serif",
                y:s/2-3,
                fill:"yellow"
            });
            text.x(-text.width()/2);
            const rect = new Konva.Rect({
                x:-(text.width()+14)/2,
                y:s/2-6,
                width:text.width()+14,
                height:22,
                fill:"gray",
                opacity:0.65,
                cornerRadius:4
            });
            group.add(rect);
            group.add(text);
        }
    }

    updateCover() {
        if (this.coverLayer) {
            const coverObjs = this.mapextra?this.mapextra.coverObjs:this.mapInfo?.coverObjs;
            if (!areSameDeep(this.coverObjs, coverObjs)) {
                this.coverGroup.destroyChildren();
                //console.log("updating cover", coverObjs);
                this.coverObjs = coverObjs;

                this.coverGroup.add(new Konva.Rect({
                    x:-1000000,
                    y:-1000000,
                    width:50000000,
                    height:50000000,
                    fill:fogColor
                }));

                for (let i in coverObjs) {
                    const o= coverObjs[i];
                    switch (o.type) {
                        case "uncover":
                            this.coverGroup.add(new Konva.Rect({
                                width:o.width,
                                height:o.height,
                                x:o.x,
                                y:o.y,
                                fill:"gray",
                                opacity:1,
                                shadowColor:"gray",
                                shadowBlur:2,
                                shadowOpacity:1,
                                shadowOffset:{ x: .00001, y: .00001 },
                                globalCompositeOperation:"destination-out"
                            }));
                            break;
                        case "circle":
                            this.coverGroup.add(new Konva.Line({
                                points:o.points,
                                lineCap:"round",
                                lineJoin:"round",
                                tension:.1,
                                stroke:"gray",
                                strokeWidth:o.diameter,
                                opacity:1,
                                shadowColor:"gray",
                                shadowBlur:2,
                                shadowOpacity:1,
                                shadowOffset:{ x: .00001, y: .00001 },
                                globalCompositeOperation:"destination-out"
                            }));
                            break;
                        case "polygon":
                            if (o.circlePoints) {
                                this.coverGroup.add(new Konva.Circle({
                                    region:o.points,
                                    diameter:o.diameter,
                                    points:o.circlePoints,
                                    globalCompositeOperation:"destination-out"
                                }));
                            }else {
                                this.coverGroup.add(new Konva.Line({
                                    points:o.points,
                                    lineCap:"round",
                                    lineJoin:"round",
                                    tension:0,
                                    fill:"gray",
                                    opacity:1,
                                    closed:true,
                                    shadowColor:"gray",
                                    shadowBlur:0.5,
                                    shadowOpacity:1,
                                    shadowOffset:{ x: .00001, y: .00001 },
                                    globalCompositeOperation:"destination-out"
                            }));
                            }
                            break;
                        case "polycircle":
                            break;
                        default:
                            console.log("unknown cover type");
                    }
                }
            }
        }
    }

    updateDrawing()  {
        const drawObjs = this.mapextra?this.mapextra.drawObjs:this.mapInfo?.drawObjs;
        if (!areSameDeep(this.drawObjs, drawObjs)) {
            this.drawLayer.destroyChildren();
            //console.log("updating drawing", drawObjs);
            this.drawObjs = drawObjs;

            for (let i in drawObjs) {
                const o= drawObjs[i];
                const clear = o.color=="clear";
    
                this.drawLayer.add(new Konva.Line({
                    points:o.points,
                    lineCap:"round",
                    lineJoin:"round",
                    tension:.1,
                    stroke:clear?"white":o.color,
                    globalCompositeOperation:clear?"destination-out":"source-over",
                    strokeWidth:o.diameter,
                }));
            }
        }
    }


    incrementLoading() {
        this.numLoading = (this.numLoading||0)+1;
        let loading = this.stage.findOne(".loading");

        if (!loading) {
            loading = new Konva.Group({x:0,y:0, offsetX:20, offsetY:20, listening:false, name:"loading"});

            loading.add(new Konva.Circle({
                x: 20,
                y: 20,
                radius: 23,
                fill: "rgb(32,32,32,0.4)"
            }));
            loading.add(new Konva.Circle({
                x: 20,
                y: 20,
                radius: 20,
                stroke: 'white',
                strokeWidth: 2,
            }));
            loading.add(new Konva.Line({
                points:[20,20, 20,0],
                stroke: 'white',
            }));
            this.topLayer.add(loading);
    
            this.loadingAnim = new Konva.Animation(function(frame) {
                loading.rotate(frame.timeDiff/10);
            }, this.topLayer);
            this.loadingAnim.start();
        }
    }

    startPingUpdate() {
        if (this.pingtimer) {
            return;
        }
        if (!campaign.getPingList().length) {
            this.pingGroup.destroyChildren();
            return;
        }
        const t=this;
        this.pingtimer = setInterval(function () {
            const np = {};
            const pp = t.pingProgress;
            const pl = campaign.getPingList();
            if (pl.length) {
                for (let i in pl) {
                    const p = pl[i];
                    const pv = (pp[p.version || p.name]||0)+1;
                    np[p.version || p.name] =pv;
                    if (pv >50) {
                        // delete old ping
                        delete np[p.version || p.name];
                        campaign.deleteCampaignContent("adventure", p.name);
                    }
                }
                t.pingProgress=np;
            } else {
                clearInterval(t.pingtimer);
                t.pingtimer=null;
                t.pingProgress = {};
            }
            t.updatePing();
        },100);
    }

    updatePing() {
        const pingList = campaign.getPingList();
        const mult = (this.gridSizeInFeet||5)/5;

        if (!pingList.length) {
            this.pingtimer && clearInterval(this.pingtimer);
            this.pingtimer=null;
            this.pingGroup.destroyChildren();
            return;
        }

        removeFromGroup(this.pingGroup.getChildren(), pingList);
        const pp = this.pingProgress;

        for (let i in pingList) {
            const p = pingList[i];
            const pv = pp[p.version || p.name]||0;
            const f = this.pingGroup.findOne("."+p.name);

            if (!f || !areSameDeepIgnore(p, f.ping, ["version","timestamp"])) {
                f && f.destroy();
                if (p.measure) {
                    if (!this.localMeasure || (p.name != this.localMeasure.name)) {
                        const group = this.genMeasure(p);
                        if (group) {
                            this.pingGroup.add(group);
                            group.zIndex(Number(i));
                        }
                    }
                } else if (pv < 10) {
                    const circle = new Konva.Circle({
                        name:p.name,
                        radius:(pv||0)*this.gridSizeInFeet/5,
                        x:p.x,
                        y:p.y,
                        strokeScaleEnabled:false,
                        strokeWidth:5,
                        stroke:p.color||"red",
                        opacity:1
                    });
                    circle.ping = p;
                    this.pingGroup.add(circle);
                    circle.zIndex(Number(i));
                }
            } else if (!p.measure) {
                if (pv > 10) {
                    f.destroy();
                } else {
                    f.radius((pv||0)*this.gridSizeInFeet/5);
                }
            }
        }
    }

    genMeasure(p) {
        const dist = Math.trunc(Math.sqrt(Math.pow(p.startX-p.endX, 2)+Math.pow(p.startY-p.endY, 2)));
        let distTxt;
        if (dist < 1000) {
            distTxt = dist+"ft.";
        } else {
            distTxt = (dist/5280).toFixed(2)+" miles";
        }
        if (dist > 3) {
            const group = new Konva.Group({name:p.name, listening:false});
            group.ping = p;
            group.add(new Konva.Arrow({
                x:p.startX,
                y:p.startY,
                points:[0,0,p.endX-p.startX,p.endY-p.startY],
                stroke:p.color||"red",
                fill:p.color||"red",
                strokeWidth:2,
                strokeScaleEnabled:false,
                pointerLength:10/this.scale,
                pointerWidth:10/this.scale
            }),
            new Konva.Text({
                    text:distTxt,
                    x:p.startX+(p.endX-p.startX)/2,
                    y:p.startY+(p.endY-p.startY)/2,
                    scaleX:1/this.scale,
                    scaleY:1/this.scale,
                    fontSize:18, 
                    fontFamily:"Convergence, sans-serif",
                    shadowEnabled:true,
                    shadowColor:'black',
                    shadowBlur:10,
                    shadowOpacity:1,
                    fill:"white"
            }));
            return group;
        }
        return null;
    }

    startPingCheck() {
        if (this.campaignMode) {
            const t=this;
            this.pingCheckTimer = setTimeout(function () {
                const pos = t.getPointerPosition();
                const obj = new Konva.Circle({
                    radius:0,
                    x:pos.x,
                    y:pos.y,
                    name:"ping",
                    draggable:true,
                });
                obj.pointer = true;

                t.tokenLayer.add(obj);
                t.stage.stopDrag();
                obj.startDrag();
                t.dragging = [obj];

                t.startMeasureTool(obj,true);
                t.pingCheckTimer=null;
                t.moved = true;
            },500)
        }
    }

    cancelPingCheck() {
        if (this.pingCheckTimer) {
            clearTimeout(this.pingCheckTimer);
            this.pingCheckTimer = null;
        }
    }


    startMeasureTool(obj, noMeasure) {
        if (this.campaignMode) {
            const start = this.getPointerPosition();
            this.localMeasure = {
                name:campaign.newUid(),
                startX:start.x,
                startY:start.y,
                type:"ping",
                color:this.playerView?"orange":"red",
                measure:!noMeasure
            }
            this.localMeasureObj = obj;
            if (obj && obj.cInfo) {
                this.localMeasure.startX = obj.cInfo.tokenX;
                this.localMeasure.startY = obj.cInfo.tokenY;
            }

            if (noMeasure) {
                this.localMeasure.x = start.x;
                this.localMeasure.y = start.y;
                campaign.updateCampaignContent("adventure",this.localMeasure)
            }
            if (!this.measureTimer) {
                const t=this;
                this.measureTimer = setInterval(function () {
                    if (t.localMeasure){
                        campaign.updateCampaignContent("adventure",t.localMeasure);
                    }
                },1000);
            }
        }       
    }

    updateMeasureTool() {
        if (this.localMeasureGroup) {
            this.localMeasureGroup.destroy();
            this.localMeasureGroup = null;
        }
        if (this.localMeasure) {
            this.localMeasure.endX = this.localMeasureObj.x();
            this.localMeasure.endY = this.localMeasureObj.y();
            this.localMeasure.measure=true;

            const group = this.genMeasure(this.localMeasure);
            if (group) {
                this.topLayer.add(group);
                this.localMeasureGroup = group;
            }
        }
    }

    cancelMeasureTool() {
        if (this.localMeasureGroup) {
            this.localMeasureGroup.destroy();
            this.localMeasureGroup = null;
        }
        if (this.measureTimer) {
            clearInterval(this.measureTimer);
            this.measureTimer=null;
        }
        
        if (this.localMeasure) {
            campaign.deleteCampaignContent("adventure", this.localMeasure.name);
            this.localMeasure = null;
            this.localMeasureObj = null;
        }
    }
    
    decrementLoading() {
        if (this.numLoading) {
            this.numLoading--;
            if (!this.numLoading) {
                this.loadingAnim.stop();
                this.loadingAnim=null;
                this.destroyName(".loading");
            }
        }
    }

    getErrorFill() {
        if (!this.errorFill) {
            const fill = new Konva.Layer({width:10, height:10, listening:false});
            fill.add(new Konva.Rect({x:0, y:0, width:5, height:5, fill:'gray'}));
            fill.add(new Konva.Rect({x:5, y:5, width:5, height:5, fill:'gray'}));
            this.errorFill=fill.toCanvas({width:10, height:10});
            fill.destroy();
        }
        return this.errorFill;
    }

    setShowGrid(show) {
        if (!!show == !!this.showGrid) {
            return;
        }
        this.showGrid=show;
        this.genGridLines();
        this.updateSelection();
    }

    genGridLines() {
        this.gridGroup.destroyChildren();

        if (this.showGrid && this.mapInfo) {
            if (this.scale < 2) {
                //console.log("too small to show grid", 5*this.scale, this.scale);
                return;
            }

            let mapX = this.viewPort.left;
            let mapY = this.viewPort.top;
            let points = [];
        
            let xStart = mapX - (mapX%5) + (-this.gridxShift) % 5 - 20;
            let yStart = mapY - (mapY%5) + (-this.gridyShift) % 5 - 20;
            let xEnd = this.viewPort.right+20;
            let yEnd = this.viewPort.bottom+20;
            //console.log("need to gen grid", xStart, yStart, xEnd, yEnd, this.viewPort);
        
            let top;
            for (let x=xStart; x<= xEnd; x+=5){
                points.push(x);
                points.push(top?yStart:yEnd);
                points.push(x);
                points.push(top?yEnd:yStart);
                top = !top;
            }

            let left;
            for (let y=yStart; y<= yEnd; y+=5){
                points.push(left?xStart:xEnd);
                points.push(y);
                points.push(left?xEnd:xStart);
                points.push(y);
                left = !left;
            }

            let grid = new Konva.Line({
                points,
                stroke:"black",
                opacity:0.7,
                strokeScaleEnabled:true,
                strokeWidth:0.1,
                listening:false
            });
            this.gridGroup.add(grid);
        }
        
    }

    setPointer(x,y) {
        const pointer = this.topLayer.findOne(".pointer");
        if (x || y) {
            if (pointer) {
                pointer.x(x);
                pointer.y(y);
            } else {
                const pgroup = new Konva.Group({listening:false, x, y, name:"pointer"});
                pgroup.add(
                    new Konva.Circle({radius:8/this.scale, fill:"#b30000", opacity:0.7}),
                    new Konva.Circle({radius:3/this.scale, fill:"red", opacity:1})
                );
                this.topLayer.add(pgroup);
            }
        } else if (pointer) {
            pointer.destroy();
        }
    }

    // stage interactions
    onMouseDown(e) {
        const obj = findObj(e);
        this.moved = false;
        this.stageTap = null;
        if (!this.moveMode) {
            this.dragging= [];
            this.unselectedObj = null;
            //console.log("stage mouse down",e,obj);
            if (obj) {
                if (obj.cInfo && this.getCombatantDraggable(obj.cInfo)) {
                    this.dragging.push(obj);
                    obj.moveToTop();
                    this.startMeasureTool(obj);
                    const selected = this.combatants.selected;
                    if (e.evt.ctrlKey || selected[obj.cInfo.id]) {
                        for (let s in this.combatants.selected) {
                            if (!this.dragging.find(function(o){return o.cInfo.id ==s})) {
                                const p = this.tokenLayer.findOne("."+s);
                                if (p && p.cInfo){
                                    this.dragging.push(p);
                                }
                            }
                        }
                    }
                } else if (obj.pin && this.getPinDraggable(obj.pin)) {
                    if (obj.pin.selected) {
                        this.dragging.push(obj);
                        obj.moveToTop();
                    } else {
                        this.unselectedObj = obj;
                    }
                } else if (obj.handle) {
                    this.dragging.push(obj);
                }
            }
            if (!this.dragging.length) {
                if (this.getStageDraggable(e)) {
                    this.dragging.push(this.stage);
                    this.lastDistance = getDistance(e);
                    this.startPingCheck();
                } else if (this.mode=="select") {
                    this.startSelectDrag(this.dragging);
                }
            }
            if (this.dragging.length) {
                this.freezeUpdates();
                const selected = [];
                for (let o of this.dragging) {
                    //console.log("start drag", o.x(), o.y(), o);
                    if (o.cInfo) {
                        selected.push(o.cInfo.id);
                    }
                    o.startDrag();
                }
                if (selected.length) {
                    this.combatants.select(selected);
                } 
                this.startDragPos = this.stage.getPointerPosition();
                this.moveMode = "dragging";
            }
        }
        this.updateSelection();
    }

    onMouseMove(e) {
        const obj = findObj(e);
        this.moved = true;
        //console.log("stage mouse move",e, obj);
    }

    onMouseUp(e) {
        const obj = findObj(e);
        //console.log("stage mouse up",e);
        if (this.moveMode == "dragging") {
            if (e.evt.touches && e.evt.touches.length) {
                //console.log("a touch changed", e);
                return;
            }
            //console.log("done dragging",e);

            if (!this.moved) {
                console.log("stage tap", obj);
                if (this.dragging && this.dragging.length==1) {
                    const {cInfo,stage,select}= this.dragging[0];
                    if (cInfo) {
                        this.events.emit("tap", cInfo.id, cInfo.index, e);
                    } else if (stage || select) {
                        this.clearSelection(true);
                        if (this.unselectedObj) {
                            if (this.unselectedObj.pin) {
                                this.selectPin(this.unselectedObj.pin.name);
                            }
                        } else {
                            this.events.emit("stagetap", e);
                            this.stageTap = this.stage.getPointerPosition();
                            console.log("stageTap", this.stageTap);
                        }
                    }
                }
                this.cancelDrag();
            } else {
                this.finishDrag();
            }
        }
        this.moveMode = null;
        this.measureTarget=null;
        this.cancelPingCheck();
        this.updateSelection();
    }

    onDragMove(e) {
        const obj = findObj(e);
        this.moved = true;
        //console.log("drag move",e,obj);
        if (this.moveMode == "dragging") {
            if (obj) {
                const pos = this.stage.getPointerPosition();
                if (obj.stage) {
                    const nextDistance = getDistance(e);
                    
                    if (nextDistance && this.lastDistance) {
                        const mult = this.lastDistance/nextDistance;
                        this.doZoom(mult, this.startDragPos,pos.x-this.startDragPos.x, pos.y-this.startDragPos.y)
                        this.startDragPos = pos;
                    } else if (!nextDistance && !this.lastDistance) {
                        this.mapPos.x = (this.width/2-this.stage.x())/this.scale;
                        this.mapPos.y = (this.height/2-this.stage.y())/this.scale;
                        this.updateStageZoom();
                    } else {
                        this.startDragPos = pos;
                        obj.stopDrag();
                        // try to avoid jumping around
                        this.updateStageZoom();
                        obj.startDrag();
                    }
                    this.lastDistance = nextDistance;
                } else if (obj.handle) {
                    const tokenObj = obj.getParent();
                    const c = tokenObj.cInfo;
                    const x = (pos.x-this.stage.x())/this.scale;
                    const y = (pos.y-this.stage.y())/this.scale;
            
                    const deg = Math.round((180-Math.atan2(x-c.tokenX, y-c.tokenY)*180/Math.PI)/5)*5;
                    tokenObj.rotation(deg);
                    obj.position({x:0,y:0});
                } else if (obj.select) {
                    this.dragSelect();
                }
            }
        }
        this.cancelPingCheck();
        this.updateMeasureTool();
    }

    onWheel(e) {
        if (!this.moveMode) {
            const mult = (e.evt.deltaY > 0)?1.1:1/1.1;
            this.doZoom(mult, this.stage.getPointerPosition());
        }
    }

    doZoom(mult, pivot, diffX, diffY) {
        const newMapPos = Object.assign({}, this.mapPos);
        newMapPos.diameter *= mult;

        const newX = ((this.width/2-pivot.x)*(1-mult)+(diffX||0))/this.scale;
        const newY = ((this.height/2-pivot.y)*(1-mult)+(diffY||0))/this.scale;

        newMapPos.x -=newX;
        newMapPos.y -=newY;
        this.mapPos = newMapPos;
        this.updateStageZoom();
        this.updateSelection();
    }

    onMouseEnter(e) {
        const obj = findObj(e);
        console.log("mouse enter",e,obj);
    }

    onMouseLeave(e) { 
        const obj = findObj(e);
        //console.log("mouse leave",e,obj);
    }

    finishDrag() {
        //console.log("finish drag", this.dragging)
        if (this.dragging) {
            const cupdates = [];
            const pupdates = []
            for (let o of this.dragging) {
                const {cInfo, pin, handle, pointer} = o;
                if (cInfo) {
                    cupdates.push({id:cInfo.id, x:o.x(), y:o.y()});
                } else if (handle) {
                    const tokenObj = o.getParent();
                    const cInfo = tokenObj.cInfo;
                    cupdates.push({id:cInfo.id, x:cInfo.tokenX, y:cInfo.tokenY, rotation:tokenObj.rotation()});
                } else if (pin) {
                    pupdates.push({name:pin.name, x:o.x(), y:o.y()});
                }
                if (pointer) {
                    o.destroy();
                }
            }
            if (cupdates.length) {
                console.log("cupdates", cupdates)
                this.combatants.changeCombatantPositions(cupdates);
            }
            if (pupdates.length) {
                console.log("pupdates", pupdates);
                this.changePins(pupdates);
            }
            this.dragging=null;
            console.log("stage", this.stage.position(), this.stage.scale(),this)
        }
        this.cancelDrag();
    }

    cancelDrag() {
        console.log("cancel drag", this.dragging)
        if (this.dragging) {
            for (let o of this.dragging) {
                o.stopDrag();
                if (o.pointer) {
                    o.destroy();
                }
            }
            this.dragging=null;
        }
        this.finishDragSelect();
        this.cancelMeasureTool();
        this.unfreezeUpdates();
    }

    startSelectDrag(dragging) {
        this.clearSelection();
        this.startDragSelect = this.getPointerPosition();
        const points = [this.startDragSelect.x,this.startDragSelect.y];
        const selectRect = new Konva.Line({
            points,
            closed:true,
            stroke:"red",
            strokeWidth:3,
            strokeScaleEnabled:false,
            dash:[6,3],
            listening:false,
        });
        this.selectRect = selectRect;
        this.topLayer.add(selectRect);

        const obj = new Konva.Circle({
            radius:0,
            position:this.startDragSelect,
            name:"select",
            draggable:true,
        });
        obj.select = true;

        this.backgroundLayer.add(obj);
        obj.startDrag();
        this.selectPoint = obj;
        dragging.push(obj);
    }

    dragSelect() {
        if (this.selectRect) {
            const pos = this.getPointerPosition();
            const {x,y} = this.startDragSelect;
            const minX = Math.min(x,pos.x), minY=Math.min(y,pos.y), maxX=Math.max(x,pos.x), maxY=Math.max(y,pos.y);
            const points = [x,y,x,pos.y,pos.x,pos.y, pos.x,y];
            this.selectRect.points(points);

            const mc = this.combatantMapDetails||[];
            const list = [];
            for (let c of mc) {
                if (this.getCombatantDraggable(c) &&
                    (c.tokenX <maxX) && (c.tokenX>minX) && (c.tokenY<maxY) && (c.tokenY>minY)
                ) {
                    list.push(c.id);
                }
            }
            this.combatants && this.combatants.select(list,false);
        }
    }

    finishDragSelect() {
        this.selectRect && this.selectRect.destroy();
        this.selectPoint && this.selectPoint.destroy();
        this.selectRect=null;
        this.selectPoint = null;
    }

    getPointerPosition() {
        const pos = this.stage.getPointerPosition();
        return {x:pos.x/this.scale+this.viewPort.left, y:pos.y/this.scale+this.viewPort.top}
    }

    updateSelection() {
        this.events.emit("selection");
    }

    clearSelection(noUpdate) {
        this.stageTap=null;
        this.selectPin();
        this.combatants && this.combatants.select();
        if (!noUpdate) {
            this.updateSelection();
        }
    }

    selectPin(name) {
        if (this.selectedPin != name) {
            this.selectedPin = name;
            this.updatePins();
        }
    }

    getSelectionType() {
        if (this.moveMode) {
            return null;
        }
        if (this.stageTap) {
            return "stage";
        }

        if (this.combatants) {
            const selectCount = Object.keys(this.combatants.selected||{}).length;
            //console.log("select count", selectCount)
            if (selectCount > 0) {
                return "combatants";
            }
        }
    }

    getSelectedPosition() {
        if (this.stageTap) {
            //console.log("stage tap pos", this.stageTap);
            return this.stageTap;
        }
        
        let boundingRect;

        if (this.combatants) {
            for (let i in this.combatants.selected) {
                const p = this.tokenLayer.findOne("."+i);
                if (p) {
                    const rect = p.getClientRect({skipShaddow:true});
                    const right = rect.x+rect.width, bottom = rect.y+rect.height;
                    if (!boundingRect) {
                        boundingRect = {left:rect.x, right, top:rect.y, bottom};
                    } else {
                        boundRect.left = Math.min(boundingRect.left, rect.x)
                        boundingRect.right = Math.max(boundingRect.right, right);
                        boundingRect.top = Math.min(boundingRect.top,rect.y);
                        boundingRect.bottom = Math.max(boundingRect.bottom, bottom);
                    }
                }
            }
        }

        //console.log("bounding rect", boundingRect);
        if (boundingRect) {
            let x = (boundingRect.left+boundingRect.right)/2;
            let y = boundingRect.bottom;
            return {x,y};
        }
        return null;
    }
}

function getDistance(e) {
    const {touches} = e.evt;
    if (touches) {
        const t1 = touches[0];
        const t2 = touches[1];

        if (t1 && t2) {
            return Math.sqrt(Math.pow(t2.screenX - t1.screenX, 2) + Math.pow(t2.screenY - t1.screenY, 2));
        }
    }
    return 0;
}

function findObj(e) {
    let cur = e && e.target;
    while (cur && !cur.name()) {
        cur = cur.parent;
    }
    if (!cur) {
        return null;
    }
    //console.log("found obj", !!cur.cInfo, !!cur.pin, cur.name())
    return cur;
}

function removeFromGroup(children, list) {
    for (let chi=0; chi<children.length;) {
        const ch = children[chi];
        const id = ch.name();
        const c = list && list.find(function(a){return (a.id||a.name)==id});
        if (!c) {
            ch.destroy();
        } else {
            chi++;
        }
    }

}

class EncounterCombatants {
    constructor (name, playerView) {
        this.name = name;
        this.playerView = playerView;
        this.onCombatantsChange = this.onCombatantsChange.bind(this);
        this.onOtherChange = this.onOtherChange.bind(this);
        this.eventSync = new EventEmitter();

        if (name == "campaign") {
            globalDataListener.onChangeCampaignContent(this.onCombatantsChange, "adventure");
            globalDataListener.onChangeCampaignContent(this.onOtherChange, "players");
        } else {
            globalDataListener.onChangeCampaignContent(this.onCombatantsChange, "plannedencounters");

        }
        globalDataListener.onChangeCampaignContent(this.onOtherChange, "monsters");
        this.load();
        this.selected = {};
    }

    destroy() {
        if (this.name == "campaign") {
            globalDataListener.removeCampaignContentListener(this.onCombatantsChange, "adventure");
            globalDataListener.removeCampaignContentListener(this.onOtherChange, "players");
        } else {
            globalDataListener.removeCampaignContentListener(this.onCombatantsChange, "plannedencounters");
        }
        globalDataListener.removeCampaignContentListener(this.onOtherChange, "monsters");
        this.delay && clearTimeout(this.delay);
        this.eventSync.removeAllListeners();
    }

    onCombatantsChange() {
        this.needLoad=1;
        this.delayChange();
    }

    delayChange() {
        if (!this.delay) {
            this.delay = setTimeout(this.doUpdate.bind(this),10);
        }
    }

    doUpdate() {
        let old;
        this.delay=null;
        if (this.needLoad) {
            old=this.combatants;
            this.load();
            this.needLoad=0;
        }
        if (this.alwaysLoad, !areSameDeep(old, this.combatants)) {
            this.eventSync.emit("change");
            this.alwaysLoad=0;
        }
    }

    onOtherChange() {
        this.alwaysLoad=1;
        this.delayChange();
    }

    load() {
        let combatants = [];
        if (this.name == "campaign") {
            const adventure = campaign.getAdventure();
            const positions = adventure.positions;
            combatants = adventure.combatants || [];

            if (positions) {
                combatants = combatants.concat();

                for (let i in combatants) {
                    let c = combatants[i];
                    const p = positions[c.id];
                    if (p) {
                        c = Object.assign({},c);
                        combatants[i]=c;
                        c.tokenX=p.tokenX;
                        c.tokenY=p.tokenY;
                        if (p.rotation != null) {
                            c.rotation=p.rotation;
                        } else {
                            delete c.rotation;
                        }
                    }
                }
            }
        } else {
            const encounter = campaign.getPlannedEncounterInfo(this.name);
            if (encounter) {
                combatants = encounter.combatants||[];

                for (let c of combatants) {
                    delete (c||{}).cInfo;
                }
            }
        }
        this.combatants=combatants;
    }

    getCombatant(id) {
        const combatants = this.combatants || [];
        for (let c of combatants) {
            if (c.id == id) {
                return c;
            }
        }
        return null;
    }

    setCombatant(cInfo) {
        const combatants = this.combatants.concat([]);
        const index = combatants.findIndex(function (c) {return c.id==cInfo.id});
        if (index >=0) {
            combatants[index] = cInfo;
            this.changeCombatants(combatants);
        } else {
            console.log("could not find combatant to change");
        }
    }

    deleteCombatant(id) {
        const combatants = this.combatants.concat([]);
        const index = combatants.findIndex(function (c) {return c.id==id});
        if (index >=0) {
            combatants.splice(index,1);
            this.changeCombatants(combatants);
        }
    }

    changeCombatants(newC) {
        this.combtants = newC;
        if (this.name == "campaign") {
            const adventure = campaign.getAdventure();
            adventure.combatants = newC;
            campaign.updateCampaignContent("adventure", adventure);
        } else {
            const encounter = campaign.getPlannedEncounterInfo(this.name);
            if (encounter) {
                for (let e of newC||[]) {
                    delete (e||{}).cInfo;
                }
        
                encounter.combatants = newC;
                campaign.updateCampaignContent("plannedencounters", encounter);
            } else {
                console.log("encounter not found for update", this.name);
            }
        }

    }

    changeCombatantPositions(vals) {
        const combatants = this.combatants.concat([]);
        const update={};

        for (let i in vals) {
            const a = vals[i];
            const index = combatants.findIndex(function (c) {return c.id == a.id});
            if (index>=0) {
                const c = Object.assign({},combatants[index]);
                combatants[index]=c;

                c.tokenX=a.x;
                c.tokenY=a.y;
                if (a.rotation != null) {
                    c.rotation=a.rotation;
                    update["positions."+c.id] = {tokenX:a.x, tokenY:a.y, rotation:a.rotation};
                } else {
                    c.rotation=(c.rotation!=null)?c.rotation:null;
                    update["positions."+c.id] = {tokenX:a.x, tokenY:a.y, rotation:c.rotation};
                }
            }
        }
        if (this.name == "campaign") {
            this.combatants=combatants;
            campaign.updateAdventure(update);
            this.eventSync.emit("change");
        } else {
            this.changeCombatants(combatants);
        }
    }

    getMapDetails(mapName) {
        const settings = campaign.getGameState();
        const names = settings.names||"full";
        const shared = ((settings.sharing||"readonly")=="open");
        const retDead = [], retActive=[], retInactive = [], retTurn=[], retObj=[], retMove=[];
        const playerView = this.playerView;

        let num=1;
        for (let i in this.combatants) {
            const c=this.combatants[i];
            let cnum=null;
    
            if ((c.ctype != "object") && (!c.state || (c.state=="active")) && !c.hidden) {
                cnum=num;
                num++;
            }
    
            if (((c.tokenMap||"").toLowerCase() == mapName) && (!c.hidden || !playerView)) {
                const info = {
                    id:c.id, 
                    index:Number(i),
                    number:cnum,
                    canMove:true,
                    hideName:c.hideName,
                    ctype:c.ctype,
                    type:c.type,
                    hidden:c.hidden,
                    tokenX:c.tokenX,
                    tokenY:c.tokenY,
                    height:c.height,
                    width:c.width,
                    diameter:c.diameter,
                    fill:c.fill,
                    opacity:c.opacity,
                    currentTurn:c.currentTurn,
                    rotation:c.rotation,
                    select:this.selected[c.id],
                    hover:c.id==this.hover,
                };

                Object.assign(info, getTokenInfo(c));

                if (playerView) {
                    switch (c.ctype) {
                        case "object":{
                            if (!c.playerControlled) {
                                // objects that are not player controlled cannot be moved by players
                                info.canMove = false;
                            }
                            break;
                        }
                        case "pc":
                        case "cmonster": {
                            if (!shared) {
                                // if not shared then you can only move your own characters
                                info.canMove = !!campaign.getMyCharacterInfo(c.name);
                            }
                            break;
                        }
                        default:
                            info.canMove = false;
                            break;
                    }
                }

                let ret;
                if (info.select) {
                    ret=retMove;
                } else if (c.ctype == "object") {
                    ret = retObj;
                } else {
                    switch (c.state) {
                        case "inactive":
                            ret = retInactive;
                            break;
                        case "active":
                        default:
                            if (c.type && (info.hp==0)) {
                                ret = retDead;
                            } else {
                                ret = retActive;
                            }
                            break;
                    }

                    if (c.currentTurn) {
                        ret = retTurn;
                    }
                }

                ret.push(info);
            }
        }
        return retObj.concat(retDead,retInactive,retActive,retTurn,retMove);
    }

    select(selList, dontReplace) {
        if (!dontReplace) {
            this.selected = {};
        }
        if (selList) {
            for (let s of selList) {
                this.selected[s]=true;
            }
        }
        this.eventSync.emit("change");
    }

    setHover(id) {
        this.hover = id;
        this.eventSync.emit("change");
    }
    
    isSelected(id) {
        return !!this.selected[id];
    }
}

const warningSVGpaths = [
    {
        data:"m46.356 0.062c-1.504 0.232-2.826 1.121-3.582 2.438l-42.108 72.949c-0.88 1.533-0.895 3.441 0 4.986 0.896 1.545 2.559 2.514 4.358 2.512h84.216c1.801 0.002 3.463-0.967 4.359-2.512 0.895-1.545 0.879-3.453 0-4.986l-42.109-72.949c-1.035-1.803-3.085-2.76-5.134-2.438z",
        fill:"#FDEE1C"
    },{
        data:"m46.744 2.121c-0.814 0.127-1.508 0.617-1.9 1.301l-42.4 73.449c-0.465 0.809-0.466 1.846 0 2.65 0.474 0.816 1.348 1.35 2.3 1.35h84.801c0.951 0 1.826-0.533 2.299-1.35 0.467-0.805 0.465-1.842 0-2.65l-42.4-73.449c-0.545-0.95-1.598-1.475-2.7-1.301zm0.4 8.449l36 63.4h-72.051l36.051-63.4z",
        fill:"#010101"
    }, {
        data:"m46.932 34.322l-1.95 11.35-8-8.35 4.5 10.65-11.2-2.75 9.5 6.551-10.899 3.75 11.5 0.35-7.101 9.049 9.9-5.898-1.1 11.449 5.1-10.301 5.3 10.201-1.301-11.451 9.951 5.75-7.25-8.898 11.5-0.551-10.951-3.6 9.402-6.701-11.152 2.9 4.25-10.65-7.799 8.451-2.2-11.301zm0.2 11.701c3.514 0 6.35 2.836 6.35 6.35s-2.836 6.4-6.35 6.4c-3.515 0-6.351-2.887-6.351-6.4s2.837-6.35 6.351-6.35zm0 0.949c-3.002 0-5.4 2.398-5.4 5.4s2.398 5.449 5.4 5.449 5.451-2.447 5.451-5.449-2.449-5.4-5.451-5.4z",
        fill:"#010101"
    }
]

function addCondition(group) {
    for (let p of warningSVGpaths) {
        const path = new Konva.Path(p);
        group.add(path);
    }
    return group;
}

function showConditionIndicator(conditions) {
    for (let i in conditions) {
        const c = conditions[i];
        if (typeof c == "object") {
            if (!c.hideIndicator) {
                return true;
            }
        } else {
            return true;
        }
    }
    return false;
}

const MapViewSize = sizeMe({monitorHeight:true, monitorWidth:true})(MapView);
export {
    MapViewSize as MapView
};